import styled from "styled-components";

export const Blog = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  .blog-container {
    background-color: ${({ theme }) => theme.global.bg.tertiary};
    max-width: 100%;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 2rem 8rem 0rem 8rem;

    &-intro {
      text-align: left;
      color: ${({ theme }) => theme.global.color.primary};

      .title-intro {
        font-size: ${({ theme }) => theme.heading.font.primary};
        font-weight: 500;
        padding: 20px 12px;
        letter-spacing: 2px;
        line-height: ${({ theme }) => theme.heading.font.lineHeight};
        display: flex;

        .icon {
          cursor: pointer;
          color: ${({ theme }) => theme.button.icon.color.primary};
          color: ${({ color }) =>
            color
              ? ({ theme }) => theme.button.icon.color.primary.secondary
              : ({ theme }) => theme.button.icon.color.tertiary};
          font-size: ${({ theme }) => theme.button.icon.size};
          background-color: ${({ theme }) => theme.button.icon.bg};
          width: 24px;
          height: 24px;
          border-radius: 20px;
          text-align: right;
          border: ${({ theme }) => theme.button.icon.border};
        }
        .icon:hover {
          color: ${({ theme }) => theme.button.icon.color.primary.secondary};
        }
        .clicked-icon {
          color: ${({ theme }) => theme.button.icon.color.primary.secondary};
        }
        .unclicked-icon {
          color: ${({ theme }) => theme.button.icon.color.primary};
        }
      }

      .title-intro span {
        color: ${({ theme }) => theme.heading.color.tertiary};
      }
      .col-icon {
        justify-content: end;
        align-items: center;
      }
    }
    &-content {
      justify-content: space-around;
      padding: 13px;
      width: 100%;
      max-width: 100%;

      .card {
        width: ${({ theme }) => theme.blogCard.width};
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
          0 6px 20px 0 rgba(0, 0, 0, 0.19);
        display: flex;
        padding: 0px;
        margin: 10px;
        margin: 0px 0px 20px 0px;
        border-radius: ${({ theme }) => theme.blogCard.border};
        transition: height 0.3s ease; /* Add this line for the transition */

        &-header {
          padding: 0px;
          margin: 0px;
        }
        &-body {
          padding: 0px;
          .header {
            display: flex;
            color: ${({ theme }) => theme.blogCard.color.primary};
            font-size: ${({ theme }) => theme.blogCard.heading.font.primary};
            padding: 20px;
            margin: 0px;
            &-profile {
              padding: 0px;
              width: 60px;
              margin-right: 10px;
              justify-content: flex-start;
              &-view {
                width: 55px;
                height: auto;
                padding: 0px;
              }
            }
            .name {
              padding: 0px;
              display: flex;
              align-items: center;
              &-text {
                margin: 0px 0px 0px 0px;
                padding: 0px;
                font-size: ${({ theme }) =>
                  theme.blogCard.description.secondaryFont};
                font-weight: bold;
                font-family: ${({ theme }) => theme.blogCard.family.raleway};
              }
            }
            .date {
              padding: 0px;
              display: flex;
              align-items: center;
              &-text {
                padding: 0px;
                font-size: ${({ theme }) =>
                  theme.blogCard.description.secondaryFont};
                font-family: ${({ theme }) => theme.blogCard.family.poppins};
              }
            }
          }
          .description {
            margin: 0px;
            font-family: ${({ theme }) => theme.blogCard.family.poppins};
            color: ${({ theme }) => theme.blogCard.color.tertiary};
            font-size: ${({ theme }) => theme.blogCard.description.primaryFont};
            padding: 0px 20px 20px 20px;
          }
          .card-read {
            padding: 0px 20px 20px 20px;
            color: ${({ theme }) => theme.blogCard.color.quatertiary};
            font-size: ${({ theme }) =>
              theme.blogCard.description.secondaryFont};
            text-decoration: none;
            font-family: ${({ theme }) => theme.blogCard.family.poppins};
          }
          .button-container {
            .btn-main {
              padding: 0.7rem 1rem;
              font-family: ${({ theme }) => theme.button.family.poppins};
              text-decoration: none;
              position: static;
              border: 0;
              cursor: pointer;
            }
            .btn-main:hover {
              margin: 10px 0px 20px 20px;
              border-radius: 10px;
              cursor: pointer;
              background-color: ${({ theme }) => theme.button.bg.secondary};
              color: ${({ theme }) => theme.button.font.color.primary};
              height: 100%;
              border: solid 1px ${({ theme }) => theme.global.border.primary};
            }
          }
          .collapse-text {
            padding: 20px;
            font-family: ${({ theme }) => theme.blogCard.family.poppins};
            font-size: ${({ theme }) =>
              theme.blogCard.description.secondaryFont};
            color: ${({ theme }) => theme.blogCard.color.tertiary};
            max-height: auto;
            overflow: hidden;
            transition: max-height 0.3s ease;
          }
        }
      }
      .card {
        &:hover {
          color: ${({ theme }) => theme.blogCard.color.tertiary};
          border-radius: 10px;
          background-color: ${({ theme }) => theme.blogCard.bg.secondary};
          height: 100%;
          border: ${({ theme }) => theme.blogCard.borderHover};
          .card-body {
            .header {
              color: ${({ theme }) => theme.blogCard.color.secondary};
              .name {
                &-text {
                  color: ${({ theme }) => theme.blogCard.color.secondary};
                }
              }
              .date {
                color: ${({ theme }) => theme.blogCard.color.secondary};
              }
            }
            .description {
              color: ${({ theme }) => theme.blogCard.color.secondary};
            }
            .collapse-text {
              color: ${({ theme }) => theme.blogCard.color.secondary};
            }
          }
        }
      }

      .card-open {
        height: auto;
        transition: height 0.3s ease;
      }

      .initial-height {
        max-height: auto;
        transition: max-height 0.3s ease;
      }
      .collapse-height {
        max-height: 500px;
        transition: max-height 0.3s ease;
        margin: 6px 8px;
      }
    }
  }
  .height {
    height: 100%;
  }
  .full-height {
    height: 100vh;
  }
  @media screen and (max-width: 1300px) {
    .blog-container {
      height: auto;
    }
  }

  @media screen and (max-width: 981px) {
    .blog-container {
      width: 100%;
      padding: 30px;
      height: 100%;
      &-intro {
        margin: 0px;
        width: 100%;
        justify-content: center;
        .title-intro {
          width: 100%;
          justify-content: center;
        }
      }
      &-content {
        width: 100%;
        padding-left: 13px;
        padding-right: 0px;
        justify-content: center;
      }
    }
  }
`;
