import {
  Airbnb,
  Amazon,
  Fedex,
  Microsoft,
  Finance,
  // UI,
  // Laptop,
  Dummy,
  Alex,
  Mobile,
  IOS,
  Android,
  IOSBlogger,
  MobileBlogger,
  AndroidBlogger,
  NextJS,
  Ruby,
  C,
  SDK,
  Xcode,
  FireBase,
  Kotlin,
  AndroidSDK,
  SQLite,
  Network,
  Surmount,
  HRMS,
  Fayredge,
  Digirota,
  Bnei,
  PDFGPT,
} from "../../Assets/images";

export const MenuData = [
  {
    title: "Home",
    url: "#Home",
    cName: "nav-links",
  },
  {
    title: "About Us",
    url: "#About",
    cName: "nav-links",
  },
  {
    title: "Service",
    url: "#Service",
    cName: "nav-links",
  },
  {
    title: "Portfolio",
    url: "#Portfolio",
    cName: "nav-links",
  },
  {
    title: "Blog",
    url: "#Blog",
    cName: "nav-links",
  },
  {
    title: "Contact",
    url: "#Contact",
    cName: "nav-links",
  },
  {
    title: "Get a Quote",
    url: "#quote",
    cName: "nav-links-mobile",
  },
];

export const BlogContent = [
  {
    header: Mobile,
    profile: MobileBlogger,
    name: "Jhone Doe",
    date: "May 20th 2020",
    description: "9 Things you need to know before building an app",
    read: "1. Define Purpose and Audience\t2. Market Research Conduct thorough market research to understand competitors, identify trends, and assess demand for your app.\t3. Choose the Right Platform Decide between native or cross-platform development based on your target audience's preferences.\t4. Budget and Monetization Establish a realistic budget and develop a clear monetization strategy, considering in-app purchases, subscriptions, or ads.\t5. User Experience (UX) .\t6. Development Tools and Technologies Stay updated on the latest development tools and technologies \t8. Testing and Quality Assurance Thoroughly test your app across devices and platforms to identify and fix bugs, ensuring a smooth user experience.\t9. Marketing and Launch Strategy .",
    class: "card-first initial-height-1",
  },
  {
    header: IOS,
    profile: IOSBlogger,
    name: "Jhone Doe",
    date: "May 20th 2020",
    description:
      "Top 5 iOS App Development Trends that will be a blast in 2021",
    read: "1. SwiftUI Adoption\tSwiftUI, Apple's modern UI framework, is gaining traction for its simplicity and efficiency\tiOS app development.\t2. Augmented Reality (AR) Integration ARKit advancements are driving immersive experiences, making augmented reality a key trend for iOS apps, particularly in gaming and e-commerce.\t3. 5G Technology The rollout of 5G technology is influencing iOS app development, enabling faster data speeds and enhanced capabilities for high-performance applications.\t4. Machine Learning (ML) and Artificial Intelligence (AI) The integration of ML and AI in iOS apps is on the rise, enhancing user experiences through personalized content, predictive analysis, and advanced automation.\t5. Privacy and Security Measures With increased focus on user privacy, iOS app developers are implementing robust security features and adhering to Apple's App Tracking Transparency policies.",
    class: "card-second initial-height-2",
  },
  {
    header: Android,
    profile: AndroidBlogger,
    name: "Jhone Doe",
    date: "May 20th 2020",
    description:
      "The Pros and Cons of Hiring Mobile App Developers from Overseas",
    read: '"The Pros and Cons of Hiring Mobile App Developers from Overseas" Pros: Cost Savings: Hiring developers from overseas can be more cost-effective, often offering competitive rates compared to local options.Global Talent Pool: Access to a diverse talent pool allows you to find specialized skills that may be scarce locally." "Cons:" "Communication Challenges: Language barriers and time zone differences may lead to communication challenges and delays." "Cultural Differences: Varied working cultures may impact collaboration and understanding of project requirements."',
    class: "card-third initial-height-3",
  },
];

export const OurWork = [
  {
    image: Surmount,
    alt: "Surmount",
    h1: "Surmount",
    description:
      "Surmount is first of its kind automated investing platform which enables any individual, regardless of their account size or level of technical skillset, to link any equities, crypto, and forex portfolio to automate their investments using AI-driven, rule based, and/or copy trading strategies.",
    country: "US",
    tech: "React JS, Python",
    class: "UI-image",
    link: "https://app.surmount.ai/",
  },
  {
    image: HRMS,
    alt: "Ampersand",
    h1: "HRMS Utility tool",
    description:
      "Utility tools for Admin to keep a track from Scheduling an interview, Offer letter creation followed by onboarding of an employee till the Joining process.",
    country: "India",
    tech: "Next JS, Java, Typescript, MongoDB",
    class: "UI-image",
    link: "https://psonboardingutility.ampersandgroup.in/",
  },
  {
    image: Finance,
    alt: "Service OS",
    h1: "ACKO - Service OS",
    description:
      "Admins assigning and scheduling task for service executives, where executives need to  inspect the cars and upload the details for each tasks assigned.",
    country: "India",
    tech: "React JS, Typescript",
    class: "UI-image",
    link: "",
  },
  {
    image: Fayredge,
    alt: "Fayredge",
    h1: "Fayredge",
    description:
      "A business consulting and services company that offers a unique 3-6-7 fair experience framework to transform experience touchpoints across design and delivery. a platform that uses digital technology and people science to measure and improve experiences for all stakeholders, with a focus on fairness.",
    country: "India | Singapore",
    tech: "React JS,Next JS, Typescript, Java",
    class: "UI-image",
    link: "https://www.fayredge.com/",
  },
  {
    image: Digirota,
    alt: "Digirota",
    h1: "Digirota",
    description: "Dummy info",
    country: "US | Turkey",
    tech: "React JS, Strapi",
    class: "UI-image",
    link: "",
  },
  {
    image: Bnei,
    alt: "Bnei baruch",
    h1: "Bnei baruch",
    description: "Dummy info",
    country: "US | Turkey",
    tech: "React JS",
    class: "UI-image",
    link: "",
  },
  {
    image: PDFGPT,
    alt: "PDF GPT",
    h1: "PDF GPT",
    description:
      "PDFGPT is for users working with PDF documents, including researchers, educators, business professionals, and others. It is helpful for tasks such as summarizing lengthy reports, generating new documents per specific criteria, or extracting data from large data sets of PDF documents.",
    country: "European",
    tech: "React JS",
    class: "UI-image",
    link: "https://www.pdfgpt.io/",
  },
];
export const ClientData = [
  {
    image: Alex,
    alt: "Alex Cooper",
    name: "Alex Cooper",
    h1: "CEO at ABC Corporation",
    description:
      "‘‘We are quite satisfied with the Derived web and our collaboration so far. We especially appreciate how they maintained the communication. The team was constantly in touch with us and available at each project step.’’",

    class: "alex-image",
  },
  {
    image: Dummy,
    alt: "Dummy client",
    name: "Dummy client",

    h1: "On demand laptop platform",
    description:
      "Printing and type setting has been the industry’s standard dummy text. Ever since the 1500s, when an unknown.",

    class: "dummy-image",
  },
];

export const Pointers = [
  {
    pointer: "8+ years of industry experience",
  },
  {
    pointer: "20+ qualified senior technology architects",
  },
  {
    pointer: "Strict NDA Signed for Complete Privacy",
  },
  {
    pointer: "Strict NDA Signed for Complete Privacy",
  },
  {
    pointer:
      "Robust Data Security: Firewall System (Palo, Alto), VPN, Anti-virus and Antimalware software, Access Control, and Data Recovery",
  },
];

export const logoData = [
  { src: Airbnb, alt: "Airbnb Logo", link: "https://www.airbnb.com" },
  { src: Amazon, alt: "Amazon Logo", link: "https://www.amazon.com" },
  { src: Fedex, alt: "Fedex Logo", link: "https://www.fedex.com" },
  {
    src: Microsoft,
    alt: "Microsoft Logo",
    link: "https://www.microsoft.com",
  },
  { src: Amazon, alt: "Amazon Logo", link: "https://www.amazon.com" },
  { src: Airbnb, alt: "Airbnb Logo", link: "https://www.airbnb.com" },
  { src: Fedex, alt: "Fedex Logo", link: "https://www.fedex.com" },
  // Add more logo data here
];

export const serviceData = [
  {
    name: "Web and Mobile development",
  },
  {
    name: "UI/UX Design",
  },
  {
    name: "Cloud Solutions",
  },
  {
    name: "DevOps",
  },
  {
    name: "Digital Marketing ",
  },
  {
    name: "QA & Testing",
  },
];

export const frontendTool = [
  {
    class: "fa-react",
  },
  {
    src: NextJS,
  },
  {
    class: "fa-wordpress",
  },
  {
    class: "fa-angular",
  },
  {
    class: "fa-vuejs",
  },
];
export const backendTool = [
  {
    class: "fa-java",
  },
  {
    class: "fa-node",
  },

  {
    class: "fa-python",
  },
  {
    src: Ruby,
  },
  {
    class: "fa-php",
  },
];
export const iosTool = [
  {
    class: "fa-swift",
  },
  {
    src: C,
  },
  {
    src: SDK,
  },
  {
    class: "fa-uikit",
  },
  {
    src: Xcode,
  },
  {
    src: FireBase,
  },
];

export const androidTool = [
  {
    src: Kotlin,
  },
  {
    src: AndroidSDK,
  },
  {
    src: FireBase,
  },
  {
    src: SQLite,
  },
  {
    src: Network,
  },
];
