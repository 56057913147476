import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { Spinner } from "react-bootstrap";

import { Contact } from "./ContactUs.Styles";
import { FileUploader } from "react-drag-drop-files";
import { Pointers } from "../Helpers/Constants";

const ContactUs = () => {
  const [scroll, setScroll] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 1900);
    });
  }, []);

  const updateFileInputStatus = (text) => {
    const fileInputStatus = document.querySelector(".form-file-status");
    if (fileInputStatus) {
      fileInputStatus.textContent = text;
    }
  };

  const handleFileChange = (event) => {
    const fileInput = event.target;

    if (fileInput.files.length > 0) {
      updateFileInputStatus(`Selected file: ${fileInput.files[0].name}`);
    } else {
      updateFileInputStatus("Select or Drop Your file here");
    }
  };

  const handleFileDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const file = event.dataTransfer.files[0];
    if (file) {
      updateFileInputStatus(`Selected file: ${file.name}`);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dropTarget = event.target;
    dropTarget.style.backgroundColor = "#f4a9501a";
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const dropTarget = event.target;
    dropTarget.style.backgroundColor = "";
  };

  const handleLoad = (e) => {
    e.preventDefault();
    const form = e.currentTarget.closest("form");

    if (form.checkValidity()) {
      setLoading(true);
      form.submit(); // Submit the form programmatically
    } else {
      form.reportValidity(); // Show validation errors
    }
  };

  return (
    <>
      <Contact>
        <Container
          className={`contact-container container-fluid ${
            scroll ? "hidden" : "show"
          }`}
          id="Contact"
        >
          <Row className="contact-row">
            <Col className="contact-row-text">
              <Row className="title-intro animation-popin">
                Contact Us for <br className="text-break" />
                <span>project discussion</span>
              </Row>
              <Row className="title-intro-mobile animation-popin">
                <p>
                  Contact Us for <span>project discussion</span>
                </p>
              </Row>
              <Row className="row-description">
                Once you fill out this form, our sales <br />
                representatives will contact you within 24 hours.
              </Row>
              <Row className="row-pointers">
                {Pointers.map((data, index) => {
                  return (
                    <Container key={index}>
                      <i className="fa-regular fa-circle-check check animation-shake"></i>
                      <p className="check-text">{data.pointer}</p>
                    </Container>
                  );
                })}
              </Row>
            </Col>
            <Col className="contact-row-form">
              <Container>
                <Row className="form-intro animation-popin">
                  Simply fill out the form or <span>email us</span>
                </Row>
                <Form
                  action="https://formsubmit.co/jesstechsolution@gmail.com"
                  method="POST"
                  enctype="multipart/form-data"
                >
                  <Form.Group className="mb-3" controlId="formGroupName">
                    <Form.Control
                      type="name"
                      placeholder="Your Name*"
                      name="name"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formGroupEmail">
                    <Form.Control
                      type="email"
                      placeholder="Your Email*"
                      name="email"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="">
                    <Form.Control
                      placeholder="Your Phone Number"
                      name="phone"
                      required
                    />
                  </Form.Group>
                  <Form.Group
                    className="mb-3"
                    controlId="exampleForm.ControlTextarea1"
                  >
                    <Form.Label></Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message.."
                      name="message"
                      required
                    />
                  </Form.Group>
                  <Container
                    className="drop-file"
                    onDrop={(e) => handleFileDrop(e)}
                    onDragOver={(e) => handleDragOver(e)}
                    onDragLeave={(e) => handleDragLeave(e)}
                  >
                    <Form.Group controlId="formFile" className="form-file">
                      <Form.Text
                        className="form-file-status"
                        htmlFor="file-input"
                        for="file-input"
                      ></Form.Text>
                      <div
                        className="form-file-handle"
                        onDrop={(e) => handleFileDrop(e)}
                        onDragOver={(e) => handleDragOver(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                      >
                        <FileUploader
                          className="form-file-handle-upload"
                          handleChange={(file) => {
                            updateFileInputStatus(
                              `Selected file: ${file.name}`
                            );
                          }}
                          label="Select or Drop Your file here"
                          name="file"
                          type="file"
                        />
                      </div>
                      <Form.Control
                        type="file"
                        className="form-controller form-file-input"
                        name="attachment"
                        onChange={handleFileChange}
                        onDragOver={(e) => handleDragOver(e)}
                        onDragLeave={(e) => handleDragLeave(e)}
                      />
                    </Form.Group>
                  </Container>
                  <Button
                    variant="primary"
                    type="submit"
                    className="btn-submit mb-3 animation-shake"
                    onClick={handleLoad}
                  >
                    {loading ? (
                      <>
                        {"Please wait..."}&nbsp;
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      </>
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Form>
              </Container>
            </Col>
          </Row>
        </Container>
      </Contact>
    </>
  );
};

export default ContactUs;
