import React from "react";

import { NavbarMobile } from "./Components/Navigation";
import { Navbar } from "./Components/Navigation";
import Banners from "./Components/Banners";
import WhoWeAre from "./Components/WhoWeAre";
import Services from "./Components/Services";
import Technology from "./Components/Technologies";
import Footer from "./Components/Footers";
import RecentWork from "./Components/RecentWorks";
import ClientSay from "./Components/ClientsSay";
import OurBlogs from "./Components/OurBlogs";
import ExpertTalk from "./Components/ExpertTalk";
import OurApproach from "./Components/OurApproach";
import ContactUs from "./Components/ContactUs";
import { Theme } from "./Styles/theme";
import { ThemeProvider } from "styled-components";
import GlobalStyle from "./Styles/global";

function App() {
  return (
    <div>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <Navbar />
        <NavbarMobile />
        <Banners />
        <WhoWeAre />
        <Services />
        <Technology />
        <RecentWork />
        <ClientSay />
        <OurApproach />
        <ExpertTalk />
        <OurBlogs />
        <ContactUs />
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
