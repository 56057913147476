import styled from "styled-components";

export const Tech = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  .tech-container {
    background-color: ${({ theme }) => theme.global.bg.primary};
    max-width: 100%;
    height: max-content;
    margin: 0px;
    padding: 5rem 8rem 3rem 8rem;
    justify-content: center;
    align-content: center;

    .tech-row {
      text-align: center;
      color: ${({ theme }) => theme.global.color.septenary};
      &-title {
        font-size: ${({ theme }) => theme.heading.font.primary};
        font-weight: 500;
        text-align: center;
        padding: 20px 0px;
        letter-spacing: 2px;
        line-height: ${({ theme }) => theme.heading.font.lineHeight};
      }
      &-title span {
        color: ${({ theme }) => theme.title.color.primary};
      }
    }
    .tech-title {
      padding-top: 20px;
      &-wrap {
        font-family: ${({ theme }) => theme.title.family.poppins};
        font-size: ${({ theme }) => theme.title.font.primary};
        .tech-title {
          &-text {
            color: ${({ theme }) => theme.title.color.primary};
            font-family: ${({ theme }) => theme.title.family.poppins};
            font-size: ${({ theme }) => theme.title.font.primary};
          }
        }
        .tech-logos {
          margin: 0px;
          width: 100%;
          &-wrap {
            padding: 10px 20px 10px 0px;
            margin: 0px;
            width: auto;
            .circle-border {
              background-color: ${({ theme }) => theme.button.bg.quinary};
              color: ${({ theme }) => theme.button.font.color.primary};
              border-radius: 20px;
              width: 65px;
              height: 65px;
              padding: 6px;
              align-items: center;
              justify-content: center;
              font-size: ${({ theme }) => theme.button.font.size.secondary};
              display: flex;
            }
            .circle-border:hover {
              background-color: ${({ theme }) => theme.button.bg.tertiary};
              border-radius: 20px;
              transition: all 0.2s ease-in-out;
              color: ${({ theme }) => theme.button.font.color.quatertiary};
              padding: 0.5rem 0.5;
              width: 65px;
              height: 65px;
            }
            .nextjs-image {
              width: 65px;
              height: 65px;
              transition: transform 0.3s ease-in-out;
            }

            .nextjs-image:hover {
              transform: scale(1.2);
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 821px) {
    .tech-container {
      padding: 30px;
      height: auto;
    }
  }
`;
