import styled from "styled-components";

export const Client = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  .client {
    background-color: ${({ theme }) => theme.global.bg.secondary};
    max-width: 100%;
    height: 100vh;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 1rem 8rem 1rem 8rem;

    &-intro {
      text-align: left;
      color: ${({ theme }) => theme.global.color.primary};

      .title-intro {
        font-size: ${({ theme }) => theme.heading.font.primary};
        font-weight: 500;
        padding: 20px 12px 0px;
        letter-spacing: 2px;
        line-height: ${({ theme }) => theme.heading.font.lineHeight};
        display: flex;
      }

      .title-intro span {
        color: ${({ theme }) => theme.heading.color.tertiary};
      }
    }

    &-work {
      padding: 10px 12px 20px 12px;
      display: flex;
      height: auto;
      justify-content: center;

      .col-image {
        border-radius: 16px 0px 0px 16px;
        padding: 30px 0px 30px 0px;
        margin: 0px;
        /* display: flex; */
        display: none; // Temporarily hiding the video
        align-items: start;
        justify-content: start;
        .image-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .alex-image {
            width: 500px;
            height: auto;
            position: relative;
          }
          .dummy-image {
            width: 300px;
            height: auto;
            position: relative;
          }
        }
        .image-container:hover {
          cursor: pointer;
          background-color: transparent;
          border-radius: ${({ theme }) => theme.button.video.radius};
          transition: all 0.2s ease-in-out;
          box-shadow: 1px 1px 22px 2px rgba(0, 0, 0, 0.84);
        }
        .video-icon-container {
          position: absolute;
          align-items: center;
          justify-content: center;
          align-content: center;
          margin: 65px 30px 30px 65px;
          transform: translate(-50%, -50%);

          .video {
            font-size: ${({ theme }) => theme.button.video.size};
            color: ${({ theme }) => theme.button.video.color};
            padding: 0px;
          }
          .circle-border {
            padding: 10px 12px 10px 15px;
            background-color: ${({ theme }) => theme.button.video.bg};
            border-radius: ${({ theme }) => theme.button.video.radius};
            color: ${({ theme }) => theme.button.video.color};
            cursor: pointer;
          }
          .circle-border:hover {
            background-color: ${({ theme }) => theme.button.video.hoverBg};
            border-radius: ${({ theme }) => theme.button.video.radius};
            transition: all 0.2s ease-in-out;
            color: ${({ theme }) => theme.button.video.hoverColor};
            padding: 0.5rem 0.5;
          }
        }
      }
      .col-text {
        border-radius: 0px 16px 16px 0px;
        display: flex;
        align-items: start;
        justify-content: center;
        padding: 30px 0px;
        width: 100%;

        .col-description {
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0px;
          .row {
            .col-heading {
              padding-top: 15px;
              padding-right: 0px;
              h1 {
                font-size: ${({ theme }) => theme.title.font.secondary};
                color: ${({ theme }) => theme.title.color.quinary};
                font-weight: bold;
                margin: 0px;
                font-family: ${({ theme }) => theme.title.family.poppins};
              }
              p {
                font-size: ${({ theme }) => theme.description.font.septenary};
                color: ${({ theme }) => theme.description.color.secondary};
                line-height: 22px;
                font-weight: 400px;
                font-family: ${({ theme }) => theme.description.family.raleway};
                margin: 0px;
              }
              span {
                color: ${({ theme }) => theme.description.color.primary};
                font-family: ${({ theme }) => theme.description.family.raleway};
                font-weight: bold;
                font-size: ${({ theme }) => theme.description.font.quinary};
              }
            }
            .col-comma {
              .image-container {
                display: flex;
                justify-content: end;

                .comma {
                  color: ${({ theme }) => theme.comma.color};
                  font-size: ${({ theme }) => theme.comma.size};
                  text-align: right;
                }
              }
            }
          }

          .icon-container {
            justify-content: left;
            text-align: left;
            padding-top: 30px;
            .icon {
              cursor: pointer;
              color: #f8f8fb;
              color: ${({ color }) => (color ? "#f4a950" : "#fff")};
              font-size: 24px;
              background-color: #000;
              width: 24px;
              height: 24px;
              border-radius: 20px;
              text-align: right;
              border: 1px solid #f8f8fb;
            }
            .icon:hover {
              color: #f4a950;
            }
            .clicked-icon {
              color: #f4a950;
            }
            .unclicked-icon {
              color: #f8f8fb;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1150px) {
    .client {
      &-work {
        .col-image {
          border-radius: 0px;
          .image-container {
            padding: 0px;
            .alex-image {
              width: 100%;
              height: auto;
            }
            .dummy-image {
              width: 100%;
              height: auto;
            }
            .UI-image {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 821px) {
    .client {
      height: auto;
      padding: 0px 30px 30px 30px;
      &-work {
        .col-image {
          border-radius: 0px;
          .image-container {
            padding: 0px;
            .alex-image {
              width: 100%;
              height: auto;
            }
            .dummy-image {
              width: 100%;
              height: auto;
            }
            .UI-image {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .client {
      padding: 0px 30px 30px 30px;

      .title-intro {
        padding-top: 30px;
      }

      &-work {
        padding: 10px;
        .col-image {
          border-radius: 0px;
          justify-content: center;
          .image-container {
            justify-content: center;
            align-items: center;

            padding: 0px;
            .alex-image {
              width: 70%;
              height: auto;
            }
            .alex-image:hover {
              cursor: pointer;
              border-radius: ${({ theme }) => theme.button.video.radius};
              transition: all 0.2s ease-in-out;
              box-shadow: 1px 1px 22px 2px rgba(0, 0, 0, 0.84);
            }
            .dummy-image {
              width: 70%;
              height: auto;
            }
            .dummy-image:hover {
              cursor: pointer;
              border-radius: ${({ theme }) => theme.button.video.radius};
              transition: all 0.2s ease-in-out;
              box-shadow: 1px 1px 22px 2px rgba(0, 0, 0, 0.84);
            }
          }
          .image-container:hover {
            animation: none;
            box-shadow: none;
          }
          .video-icon-container {
            margin: 5rem 0rem 0px 4rem;
          }
        }
        .col-text {
          padding: 0px 0px 0px 0px;

          .col-heading {
            padding-top: 0px !important;
          }
        }
      }
    }
  }
`;
