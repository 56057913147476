import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Work } from "./RecentWorks.Style";
import { OurWork } from "../Helpers/Constants";
import { Button } from "react-bootstrap";

const RecentWork = () => {
  const [scroll, setScroll] = useState(false);
  const [prevClick, setPrevClick] = useState(false);
  const [nextClick, setNextClick] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 1600);
    });
  }, []);

  const previousWork = () => {
    setPrevClick(true);
    setNextClick(false);
    const prevIndex = (currentIndex - 1 + OurWork.length) % OurWork.length;
    setCurrentIndex(prevIndex);
  };
  const nextWork = () => {
    setNextClick(true);
    setPrevClick(false);
    const nextIndex = (currentIndex + 1) % OurWork.length;
    setCurrentIndex(nextIndex);
  };

  const currentWork = OurWork[currentIndex];

  return (
    <>
      <Work>
        <Container className="work-container">
          <Row className={`work-container-intro ${scroll ? "hidden" : "show"}`}>
            <Col
              xl={10}
              lg={10}
              sm={6}
              md={6}
              xs={6}
              className="title-intro animation-popin bcolor "
            >
              <h1>
                Our Recent <span>Work</span>
              </h1>
            </Col>
            {OurWork.length > 1 && (
              <Col
                xl={2}
                lg={2}
                sm={6}
                md={6}
                xs={6}
                className="title-intro bcolor col-icon animation-popin"
              >
                <i
                  className={`fa-solid fa-circle-chevron-left icon ${
                    prevClick ? "clicked-icon" : "unclicked-icon"
                  }`}
                  onClick={previousWork}
                ></i>
                &nbsp;
                <i
                  className={`fa-solid fa-circle-chevron-right icon ${
                    nextClick ? "clicked-icon" : "unclicked-icon"
                  }`}
                  onClick={nextWork}
                ></i>
              </Col>
            )}
          </Row>
          <Row
            className={`work-container-content animation-popin ${
              scroll ? "hidden" : "show"
            }`}
          >
            <Col className="work-image" md={6} xl={6} sm={12}>
              <div className="work-image-container">
                <img
                  src={currentWork.image}
                  alt={currentWork.alt}
                  className={`${currentWork.class} anim-pop`}
                ></img>
              </div>
            </Col>
            <Col className="work-content" md={6} xl={6} sm={12}>
              <div className="work-content-description">
                <h1>{currentWork.h1}</h1>
                <br />
                <p>{currentWork.description}</p>
                <br />
                <p>
                  <span>Country:</span>&nbsp;&nbsp;&nbsp;
                  {currentWork.country}
                </p>
                <br />
                <p>
                  <span>Core Tech:</span>&nbsp;&nbsp;&nbsp;
                  {currentWork.tech}
                </p>
                {currentWork.link && (
                  <div className="button-container">
                    <a href={currentWork.link} target="_blank" rel="noreferrer">
                      <Button className="btn-main animation-shake">
                        Explore More
                      </Button>
                    </a>
                  </div>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </Work>
    </>
  );
};

export default RecentWork;
