import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {
  HoverNextJS,
  HoverRuby,
  HoverC,
  HoverSDK,
  HoverXcode,
  HoverFireBase,
  HoverKotlin,
  HoverAndroidSDK,
  HoverSQLite,
  HoverNetwork,
} from "../../Assets/images";

import { Tech } from "./Technologies.Style";
import {
  backendTool,
  frontendTool,
  iosTool,
  androidTool,
} from "../Helpers/Constants";

const Technology = () => {
  const [scroll, setScroll] = useState(false);
  const [isNextHovered, setNextHovered] = useState(false);
  const [isRubyHovered, setRubyHovered] = useState(false);
  const [isCHovered, setCHovered] = useState(false);
  const [isSDKHovered, setSDKHovered] = useState(false);
  const [isXcodeHovered, setXcodeHovered] = useState(false);
  const [isFireBaseHovered, setFireBaseHovered] = useState(false);
  const [isKotlinHovered, setKotlinHovered] = useState(false);
  const [isAndroidSDKHovered, setAndroidSDKHovered] = useState(false);
  const [isAndroidFireBaseHovered, setAndroidFireBaseHovered] = useState(false);
  const [isSQLHovered, setSQLHovered] = useState(false);
  const [isNetworkHovered, setNetworkHovered] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 1300);
    });
  }, []);
  return (
    <>
      <Tech>
        <Container className="tech-container" id="Portfolio">
          <Row className={`tech-row ${scroll ? "hidden" : "show"}`}>
            <h1 className="tech-row-title bcolor animation-popin">
              <span>Technologies</span> we work with
            </h1>
          </Row>
          <Row className={`tech-title ${scroll ? "hidden" : "show"}`}>
            <Col className="tech-title-wrap" xl={5} lg={5} sm={5}>
              <Row className="tech-title">
                <p className="tech-title-text">Frontend</p>
              </Row>
              <Row className="tech-logos">
                {frontendTool.map((data, index) => {
                  return (
                    <Col
                      className="tech-logos-wrap"
                      md={3}
                      xl={3}
                      sm={3}
                      key={index}
                    >
                      {data.src ? (
                        <img
                          onMouseEnter={() => setNextHovered(true)}
                          onMouseLeave={() => setNextHovered(false)}
                          src={isNextHovered ? HoverNextJS : data.src}
                          alt="Next.js Logo"
                          className="circle-border animation-shake nextjs-image"
                        />
                      ) : (
                        <i
                          className={`fa-brands ${data.class} circle-border animation-shake`}
                        ></i>
                      )}
                    </Col>
                  );
                })}
              </Row>
              ;
            </Col>
            <Col xl={1} lg={1} sm={1} className="tech-title-space">
              &nbsp;
            </Col>
            <Col xl={5} lg={5} sm={5} className="tech-title-wrap">
              <Row className="tech-title">
                <p className="tech-title-text">Backend</p>
              </Row>
              <Row className="tech-logos">
                {backendTool.map((data, index) => {
                  return (
                    <Col
                      className="tech-logos-wrap"
                      md={3}
                      xl={3}
                      sm={3}
                      key={index}
                    >
                      {data.src ? (
                        <img
                          onMouseEnter={() => setRubyHovered(true)}
                          onMouseLeave={() => setRubyHovered(false)}
                          src={isRubyHovered ? HoverRuby : data.src}
                          alt="Ruby Logo"
                          className="circle-border animation-shake ruby-image"
                        />
                      ) : (
                        <i
                          className={`fa-brands ${data.class} circle-border animation-shake`}
                        ></i>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
          <Row className={`tech-title ${scroll ? "hidden" : "show"}`}>
            <Col className="tech-title-wrap" xl={5} lg={5} sm={5}>
              <Row className="tech-title">
                <p className="tech-title-text">iOS</p>
              </Row>
              <Row className="tech-logos">
                {iosTool.map((data, index) => {
                  return (
                    <Col
                      className="tech-logos-wrap"
                      md={3}
                      xl={3}
                      sm={3}
                      key={index}
                    >
                      {data.src ? (
                        <img
                          onMouseEnter={() => {
                            if (index === 1) {
                              setCHovered(true);
                            }
                            if (index === 2) {
                              setSDKHovered(true);
                            }
                            if (index === 4) {
                              setXcodeHovered(true);
                            }
                            if (index === 5) {
                              setFireBaseHovered(true);
                            }
                          }}
                          onMouseLeave={() => {
                            if (index === 1) {
                              setCHovered(false);
                            }
                            if (index === 2) {
                              setSDKHovered(false);
                            }
                            if (index === 4) {
                              setXcodeHovered(false);
                            }
                            if (index === 5) {
                              setFireBaseHovered(false);
                            }
                          }}
                          src={
                            index === 1
                              ? isCHovered
                                ? HoverC
                                : data.src
                              : index === 2
                              ? isSDKHovered
                                ? HoverSDK
                                : data.src
                              : index === 4
                              ? isXcodeHovered
                                ? HoverXcode
                                : data.src
                              : index === 5
                              ? isFireBaseHovered
                                ? HoverFireBase
                                : data.src
                              : data.src
                          }
                          alt={
                            index === 1
                              ? "C"
                              : index === 2
                              ? "IOS SDK"
                              : index === 4
                              ? "Xcode"
                              : "FireBase"
                          }
                          className="circle-border animation-shake"
                        />
                      ) : (
                        <i
                          className={`fa-brands ${data.class} circle-border animation-shake`}
                        ></i>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
            <Col xl={1} lg={1} sm={1} className="col-space">
              &nbsp;
            </Col>
            <Col xl={5} lg={5} sm={5} className="tech-title-wrap">
              <Row className="tech-title">
                <p className="tech-title-text">Android</p>
              </Row>
              <Row className="tech-logos">
                {androidTool.map((data, index) => {
                  return (
                    <Col
                      className="tech-logos-wrap"
                      md={3}
                      xl={3}
                      sm={3}
                      key={index}
                    >
                      {data.src ? (
                        <img
                          onMouseEnter={() => {
                            if (index === 0) {
                              setKotlinHovered(true);
                            }
                            if (index === 1) {
                              setAndroidSDKHovered(true);
                            }
                            if (index === 2) {
                              setAndroidFireBaseHovered(true);
                            }
                            if (index === 3) {
                              setSQLHovered(true);
                            }
                            if (index === 4) {
                              setNetworkHovered(true);
                            }
                          }}
                          onMouseLeave={() => {
                            if (index === 0) setKotlinHovered(false);
                            if (index === 1) {
                              setAndroidSDKHovered(false);
                            }
                            if (index === 2) {
                              setAndroidFireBaseHovered(false);
                            }
                            if (index === 3) {
                              setSQLHovered(false);
                            }
                            if (index === 4) {
                              setNetworkHovered(false);
                            }
                          }}
                          src={
                            index === 0
                              ? isKotlinHovered
                                ? HoverKotlin
                                : data.src
                              : index === 1
                              ? isAndroidSDKHovered
                                ? HoverAndroidSDK
                                : data.src
                              : index === 2
                              ? isAndroidFireBaseHovered
                                ? HoverFireBase
                                : data.src
                              : index === 3
                              ? isSQLHovered
                                ? HoverSQLite
                                : data.src
                              : index === 4
                              ? isNetworkHovered
                                ? HoverNetwork
                                : data.src
                              : data.src
                          }
                          alt="android"
                          className="circle-border animation-shake"
                        />
                      ) : (
                        <i
                          className={`fa-brands ${data.class} circle-border animation-shake`}
                        ></i>
                      )}
                    </Col>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </Container>
      </Tech>
    </>
  );
};

export default Technology;
