import styled from "styled-components";

export const NavbarStyles = styled.div`
  nav {
    width: 100%;
  }
  .navbar {
    background-color: ${({ theme }) => theme.nav.font.color.tertiary};
    height: 80px;
    &-items {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1.2rem;
      padding: 0 30px 0px 30px;
      background-color: ${({ theme }) => theme.nav.font.color.tertiary};
      box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
      width: 100%;
      position: fixed;
      height: 80px;
      z-index: 1;
      left: 50%;
      transform: translate(-50%);
      .logo {
        justify-self: start;
        cursor: pointer;
        padding-left: 6rem;
        .jesstech-logo {
          cursor: pointer;
          width: 168px;
          height: 36px;
        }
      }
      .menu-icons {
        display: none;
        .fa-bars,
        .fa-times {
          color: ${({ theme }) => theme.nav.font.color.primary};
          cursor: pointer;
        }
        .fa-bars:hover,
        .fa-times:hover {
          background-color: ${({ theme }) => theme.nav.font.color.secondary};
          color: ${({ theme }) => theme.nav.font.color.primary};
          padding: 10px;
          border-radius: 4px;
        }
      }
      .nav-menu {
        display: grid;
        grid-template-columns: repeat(7, auto);
        grid-gap: 10px;
        list-style: none;
        align-items: center;
        justify-content: end;
        margin-bottom: 0px;
        .nav-links {
          font-size: ${({ theme }) => theme.nav.font.size};
          line-height: 17.3px;
          color: ${({ theme }) => theme.nav.font.color.primary};
          white-space: nowrap;
          text-decoration: none;
          padding: 0.7rem 1rem;
        }
        .nav-links:hover {
          background-color: ${({ theme }) => theme.nav.font.color.secondary};
          border-radius: 4px;
          transition: all 0.2s ease-in-out;
          color: ${({ theme }) => theme.nav.font.color.tertiary};
          padding: 0.7rem 1rem;
        }
        .nav-links-mobile {
          padding: 0.7rem 1rem;
          white-space: nowrap;
          background-color: ${({ theme }) => theme.nav.font.color.secondary};
          border-radius: 5px;
          text-decoration: none;
          font-size: 14px;
          color: ${({ theme }) => theme.nav.font.color.tertiary};
        }
        .nav-links-mobile:hover {
          border-radius: 5px;
          color: ${({ theme }) => theme.nav.font.color.primary};
          background-color: ${({ theme }) => theme.nav.font.color.tertiary};
          height: 70%;
          border: solid 1px ${({ theme }) => theme.nav.font.color.primary};
        }
      }
      .nav-menu.active {
        left: 0%;
        .nav-menu-links {
          font-size: ${({ theme }) => theme.nav.font.size};
          line-height: 17.3px;
          color: ${({ theme }) => theme.nav.font.color.primary};
          text-decoration: none;
          padding: 0.7rem 1rem;
        }
        .nav-menu-links:hover {
          background-color: ${({ theme }) => theme.nav.font.color.secondary};
          border-radius: 4px;
          transition: all 0.2s ease-in-out;
          color: ${({ theme }) => theme.nav.font.color.tertiary};
          padding: 0.7rem 1rem;
        }
        .nav-menu-links-mobile {
          padding: 0.7rem 1rem;
          white-space: nowrap;
          background-color: ${({ theme }) => theme.nav.font.color.secondary};
          border-radius: 5px;
          text-decoration: none;
          font-size: 14px;
          color: ${({ theme }) => theme.nav.font.color.tertiary};
          font-weight: 500;
        }
        .nav-menu-links-mobile:hover {
          border-radius: 5px;

          background-color: ${({ theme }) => theme.nav.font.color.tertiary};
          height: 50%;
          border: solid 1px ${({ theme }) => theme.nav.font.color.primary};
        }
      }
    }
  }

  @media screen and (max-width: 821px) {
    .navbar {
      z-index: 99;
      background-color: ${({ theme }) => theme.nav.font.color.tertiary};
      &-items {
        .logo {
          padding-left: 0;
        }
        .menu-icons {
          display: block;
          .fa-bars,
          .fa-times {
            color: ${({ theme }) => theme.nav.font.color.primary};
            cursor: pointer;
          }
        }
        .nav-menu {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background: ${({ theme }) => theme.nav.font.color.tertiary};
          box-shadow: 0 8px 32px 0 rgba(188, 192, 243, 0.37);
          border-radius: 13px;
          width: 100%;
          height: auto;
          position: absolute;
          top: 1%;
          left: -110%;
          grid-gap: 8px;
          align-items: stretch;
          padding: 80px 0 30px 0;
          margin: 0;
          z-index: -1;
          transition: all 0.3s ease-in-out;
          align-items: center;
          .nav-links {
            display: block;
            width: 100%;
            padding: 1rem 0;
            color: ${({ theme }) => theme.nav.font.color.primary};
            font-weight: bold;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .nav-links:hover {
            backdrop-filter: blur(20px);
            background: ${({ theme }) => theme.nav.font.color.tertiary};
            width: 100%;
            color: ${({ theme }) => theme.nav.font.color.primary};
            border: 1px solid ${({ theme }) => theme.nav.font.color.primary};
          }
          .nav-links-mobile {
            text-align: center;
            font-weight: bold;
          }
          .nav-links-mobile:hover {
            color: ${({ theme }) => theme.nav.font.color.primary};
          }
        }
        .nav-menu.active {
          left: 0%;
          .nav-menu-links-mobile {
            text-align: center;
            font-weight: bold;
          }

          .nav-menu-links {
            display: block;
            width: 100%;
            padding: 1rem 0;
            color: ${({ theme }) => theme.nav.font.color.primary};
            font-weight: bold;
            justify-content: center;
            align-items: center;
            text-align: center;
          }
          .nav-menu-links:hover {
            background: rgba(255, 255, 255, 0.25);
            backdrop-filter: blur(20px);
            background: ${({ theme }) => theme.nav.font.color.secondary};
            width: 100%;
            color: ${({ theme }) => theme.nav.font.color.tertiary};
          }
        }
      }
    }
  }

  @media screen and (max-width: 356px) {
    .navbar {
      width: 100%;
      position: fixed;
      &-items {
        width: 300px;
        padding: 10px;
      }
    }
  }
  @media screen and (max-width: 1010px) and (min-width: 822px) {
    .navbar {
      &-items {
        overflow: scroll;
      }
    }
  }
`;

export const NavMobileStyles = styled.div`
  @media screen and (max-width: 500px) {
    .home {
      width: 100%;
      height: auto;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(222, 225, 2230) 0%,
        rgba(207, 207, 221, 1) 94%
      );
    }
  }
`;
