import { React, useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { OurServices } from "./Turnkey.Style";
import { Turnkey } from "../../Assets/images";
import { serviceData } from "../Helpers/Constants";

const Services = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 900);
    });
  }, []);
  return (
    <>
      <OurServices>
        <Container
          id="Service"
          className={`service-container container-fluid ${
            scroll ? "hidden" : "show"
          }`}
        >
          <Container className="sub-container">
            <Row className="service-row">
              <Col className="service-row-img-wrap" xs={4} sm={12}>
                <img src={Turnkey} alt="Services" className="service-image" />
              </Col>
              <Col xs={1} className="service-row-void">
                &nbsp;
              </Col>
              <Col className="service-row-intro" xs={7} sm={12}>
                <div className="service-content ">
                  <p className="service-content-title title animation-popin ">
                    WHO WE DO
                  </p>
                  <h1 className="service-content-intro bcolor animation-popin ">
                    Our Turnkey&nbsp;<span>Services</span>
                  </h1>
                </div>
                <div className="service-list">
                  {serviceData.map((data, index) => {
                    return (
                      <div className="service-list-body" key={index}>
                        <a className="service-list-body-icon pe-none">
                          <i className="fa-solid fa-circle-plus fa-xl circle-border"></i>{" "}
                        </a>
                        <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>

                        <a
                          target="_blank"
                          className="service-list-body-text pe-none"
                        >
                          {data.name}
                        </a>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </OurServices>
    </>
  );
};

export default Services;
