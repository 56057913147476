import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { Button } from "react-bootstrap";

import { Talk } from "./ExpertTalk.Styles";

const ExpertTalk = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 3600);
    });
  }, []);
  return (
    <>
      <Talk>
        <Container className="container">
          <Row className={`discussion-content ${scroll ? "hidden" : "show"}`}>
            <h1 className="discussion-content-intro bcolor animation-popin">
              Let&#39;s Discuss Your Project{" "}
            </h1>
            <p className="discussion-content-text">
              Let us know your project idea and Get free consultation to turn it
              into an amazing digital product.
            </p>
            <div className="discussion-content-btn">
              <Button className="btn-main animation-shake">
                Talk to Our Expert
              </Button>
            </div>
          </Row>
        </Container>
      </Talk>
    </>
  );
};

export default ExpertTalk;
