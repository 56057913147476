import styled from "styled-components";

export const Contact = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  input {
    width: 100%;
  }
  .contact-container {
    background-color: ${({ theme }) => theme.global.bg.primary};
    max-width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px;
    padding: 1rem 8rem 2rem 8rem;
    border-bottom: 1px solid ${({ theme }) => theme.global.border.quinary};

    .contact-row {
      &-text {
        color: ${({ theme }) => theme.global.color.septenary};
        padding-left: 10px;
        justify-content: center;
        .title-intro {
          font-size: ${({ theme }) => theme.heading.font.primary};
          font-weight: 500;
          text-align: left;
          padding: 20px 12px 20px 10px;
          letter-spacing: 2px;
          line-height: ${({ theme }) => theme.heading.font.lineHeight};
          display: flex;
        }
        .title-intro-mobile {
          display: none;
        }
        .title-intro span {
          color: ${({ theme }) => theme.heading.color.tertiary};
          padding: 0px;
          display: block;
          width: 100%;
        }
        .row-description {
          color: ${({ theme }) => theme.description.color.senary};
          font-size: ${({ theme }) => theme.description.font.secondary};
          padding-left: 10px;
        }
        .row-pointers {
          padding: 10px 0px 0px 10px;
          .container {
            width: 100%;
            display: flex;
            align-items: top;
            padding: 20px 0px 0px 0px;
            font-size: ${({ theme }) => theme.description.font.secondary};
            .check {
              padding: 4px 8px 0px 0px;
              color: ${({ theme }) => theme.description.color.septenary};
            }
            .check:hover {
            }
            .check-text {
              margin: 0px;
              align-items: center;
              font-family: ${({ theme }) => theme.description.family.poppins};
            }
            .check-text:hover {
              color: ${({ theme }) => theme.description.color.septenary};
            }
          }
        }
      }
      &-form {
        background-color: ${({ theme }) => theme.global.bg.septenary};
        border-radius: 10px;
        padding-left: 20px;
        .form-intro {
          font-size: ${({ theme }) => theme.heading.font.primary};
          font-weight: 500;
          text-align: left;
          padding: 20px 12px 20px 10px;
          letter-spacing: 2px;
          line-height: ${({ theme }) => theme.heading.font.lineHeight};
          display: flex;
        }
        .form-intro span {
          color: ${({ theme }) => theme.heading.color.tertiary};
          padding: 0px;
          display: block;
          width: 100%;
        }
        .drop-file {
          padding: 0px 0px;
          .form-file {
            padding: 0px 0px 20px 0px;

            svg {
              display: none;
            }
            .input {
              width: 100%;
            }
            label {
              background-color: ${({ theme }) => theme.label.bg};
              border: ${({ theme }) => theme.label.border};
              padding: 6px 15px;
              width: 100%;
              border-radius: 4px;
              text-align: center;
              color: ${({ theme }) => theme.label.color};
              max-width: 100%;
              text-align: center;
              font-size: ${({ theme }) => theme.label.size};
            }
            label:hover {
              background-color: ${({ theme }) => theme.label.bgHover};
              color: ${({ theme }) => theme.label.colorHover};
            }

            span {
              width: 100%;
              text-align: center;
              color: ${({ theme }) => theme.label.color};
              font-size: ${({ theme }) => theme.label.size};
              text-decoration: none;
            }
            &-input {
              display: none;
              width: 100%;
            }
          }
        }

        .btn-submit {
          border: ${({ theme }) => theme.label.border};
          width: 100%;
          background-color: ${({ theme }) => theme.label.button.bg};
          color: ${({ theme }) => theme.label.button.color};
        }
        .btn-submit:hover {
          color: ${({ theme }) => theme.label.colorHover};
          background-color: ${({ theme }) => theme.label.bgHover};
        }
      }
    }
  }

  @media screen and (min-width: 800px) and (max-width: 1100px) {
    .contact-container {
      width: 100%;
      padding-left: 30px;
      padding-right: 40px;
      height: auto;
    }
  }

  @media screen and (max-width: 799px) {
    .contact-container {
      width: 100%;
      height: auto;
      padding: 40px;
      .contact-row {
        &-text {
          flex: auto;
          width: 100%;
          display: block;
          padding-bottom: 20px;
          .title-intro {
            display: none;
          }
          .title-intro-mobile {
            display: flex;
            font-size: ${({ theme }) => theme.heading.font.primary};
            font-weight: 500;
            text-align: left;
            padding: 20px 12px 20px 10px;
            letter-spacing: 2px;
            line-height: ${({ theme }) => theme.heading.font.lineHeight};
          }
          p {
            padding: 0px;
          }
          .title-intro-mobile span {
            color: ${({ theme }) => theme.heading.color.tertiary};
          }
        }
        &-form {
          width: 100%;
          display: block;
        }
      }
    }
  }

  @media screen and (max-width: 415px) {
    label {
      min-width: 100%;
    }
    .contact-container {
      .contact-row {
        &-form {
          width: 100%;
          display: block;
          .form-file {
            min-width: 100%;
            margin: 0px;
            .form-file {
              &-handle {
                min-width: 100%;
                &-upload {
                  min-width: 100%;
                }
              }
              .form-controller {
                min-width: 100%;
              }
            }
          }
        }
      }
    }
  }
`;
