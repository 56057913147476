import styled from "styled-components";

export const NavMobile = styled.div`
  @media screen and (max-width: 500px) {
    .home {
      width: 100%;
      height: auto;
      background: rgb(2, 0, 36);
      background: linear-gradient(
        90deg,
        rgba(222, 225, 2230) 0%,
        rgba(207, 207, 221, 1) 94%
      );
    }
  }
`;
