const RALEWAY_FONTS_FAMILY = "'Raleway', sans-serif";
const POPPINS_FONTS_FAMILY = '"Poppins", sans-serif';
const COLOR_WHITE = "#FFFFFF";
const COLOR_WHITE_SECONDARY = "#f8f8fb";
const COLOR_WHITE_TERTIARY = "#f9f9f9";
const COLOR_ORANGE = "#f4a950";
const COLOR_BLACK = "#000000";
const COLOR_BLACK_SECONDARY = "#181108";
const COLOR_BLACK_TERTIARY = "#171717";
const COLOR_GREY = "#928e8e";
const COLOR_GREY_SECONDARY = "#696969";
const COLOR_COMMA = "#f9f1e8";

export const Theme = {
  mode: "light",
  heading: {
    font: {
      primary: "34px",
      lineHeight: "38px",
      secondary: "30px",
      tertiary: "40px",
      height: "42px",
    },
    color: {
      primary: COLOR_WHITE,
      secondary: COLOR_BLACK,
      tertiary: COLOR_ORANGE,
    },
    family: {
      raleway: RALEWAY_FONTS_FAMILY,
      poppins: POPPINS_FONTS_FAMILY,
    },
  },
  title: {
    font: {
      primary: "16px",
      secondary: "20px",
      tertiary: "22px",
    },
    color: {
      primary: COLOR_ORANGE,
      secondary: COLOR_WHITE,
      tertiary: COLOR_WHITE_TERTIARY,
      quatertiary: COLOR_BLACK,
      quinary: COLOR_BLACK_TERTIARY,
    },
    family: {
      raleway: RALEWAY_FONTS_FAMILY,
      poppins: POPPINS_FONTS_FAMILY,
    },
  },
  description: {
    font: {
      primary: "28px",
      secondary: "16px",
      tertiary: "20px",
      quaternary: "18px",
      septenary: "14px",
      quinary: "15px",
    },
    color: {
      primary: COLOR_BLACK_TERTIARY,
      secondary: COLOR_GREY_SECONDARY,
      tertiary: COLOR_WHITE_TERTIARY,
      quatertiary: COLOR_BLACK,
      quinary: COLOR_BLACK_SECONDARY,
      septenary: COLOR_ORANGE,
      senary: COLOR_WHITE,
    },
    family: {
      raleway: RALEWAY_FONTS_FAMILY,
      poppins: POPPINS_FONTS_FAMILY,
    },
  },
  global: {
    bg: {
      primary: COLOR_BLACK,
      secondary: COLOR_WHITE_TERTIARY,
      tertiary: COLOR_WHITE,
      quatertiary: "#e6ebf9",
      quinary: COLOR_WHITE_SECONDARY,
      septenary: COLOR_WHITE,
    },
    border: {
      primary: COLOR_WHITE,
      secondary: COLOR_WHITE_SECONDARY,
      tertiary: COLOR_ORANGE,
      quatertiary: COLOR_BLACK_SECONDARY,
      quinary: "#434343",
      septenary: "#ddd",
    },

    color: {
      primary: COLOR_BLACK,
      secondary: COLOR_BLACK_SECONDARY,
      tertiary: COLOR_BLACK_TERTIARY,
      quatertiary: COLOR_GREY,
      quinary: COLOR_GREY_SECONDARY,
      senary: COLOR_WHITE_SECONDARY,
      septenary: COLOR_WHITE,
      highlights: COLOR_ORANGE,
    },
    fonts: {
      size: "14px",
    },
    family: {
      raleway: RALEWAY_FONTS_FAMILY,
      poppins: POPPINS_FONTS_FAMILY,
    },
  },
  button: {
    bg: {
      primary: COLOR_ORANGE,
      secondary: COLOR_BLACK,
      tertiary: COLOR_WHITE,
      quatertiary: COLOR_BLACK_SECONDARY,
      quinary: "#232323",
      senary: COLOR_BLACK_TERTIARY,
    },
    font: {
      size: {
        primary: "13px",
        secondary: "40px",
        tertiary: "15px",
      },
      color: {
        primary: COLOR_WHITE,
        secondary: COLOR_BLACK,
        tertiary: COLOR_WHITE_SECONDARY,
        quatertiary: COLOR_ORANGE,
      },
      weight: 500,
    },
    family: {
      raleway: RALEWAY_FONTS_FAMILY,
      poppins: POPPINS_FONTS_FAMILY,
    },
    icon: {
      color: {
        primary: COLOR_WHITE_SECONDARY,
        secondary: COLOR_ORANGE,
        tertiary: COLOR_WHITE,
      },
      size: "24px",
      bg: COLOR_BLACK,
      border: "1px solid #f8f8fb;",
    },
    video: {
      size: "40px",
      color: COLOR_WHITE,
      bg: COLOR_GREY,
      hoverBg: COLOR_ORANGE,
      hoverColor: COLOR_BLACK,
      radius: "30px",
    },
  },
  blogCard: {
    bg: {
      primary: COLOR_WHITE,
      secondary: COLOR_BLACK,
      tertiary: COLOR_ORANGE,
    },
    color: {
      primary: COLOR_GREY_SECONDARY,
      secondary: COLOR_WHITE,
      tertiary: COLOR_BLACK_TERTIARY,
      quatertiary: COLOR_ORANGE,
    },
    heading: {
      font: {
        size: "14px",
        weight: "bold",
      },
    },
    family: {
      poppins: POPPINS_FONTS_FAMILY,
      raleway: RALEWAY_FONTS_FAMILY,
    },
    description: {
      primaryFont: "18px",
      secondaryFont: "17px",
    },
    width: "350px",
    border: "12px",
    borderHover: "solid 1px #f4a950",
  },
  nav: {
    font: {
      color: {
        primary: COLOR_WHITE,
        secondary: COLOR_ORANGE,
        tertiary: COLOR_BLACK,
      },
      size: "16px",
    },
  },
  comma: {
    color: COLOR_COMMA,
    size: "75px",
  },
  approach: {
    radius: "50%",
    bgcolor: "#eee",
    border: "solid 1px #ddd",
    color: "#6056d3",
  },
  label: {
    bg: "#f4a9501a",
    border: "1px solid #f4a950",
    color: COLOR_GREY_SECONDARY,
    bgHover: COLOR_BLACK,
    colorHover: COLOR_WHITE,
    size: "1rem",
    button: {
      bg: COLOR_ORANGE,
      color: COLOR_BLACK,
    },
  },
  footer: {
    font: {
      size: {
        primary: "16px",
        secondary: "14px",
        tertiary: "12px",
        quatertiary: "1.2rem",
      },
      color: {
        primary: COLOR_WHITE,
        secondary: COLOR_ORANGE,
      },
      family: {
        poppins: POPPINS_FONTS_FAMILY,
        raleway: RALEWAY_FONTS_FAMILY,
      },
    },
    circle: {
      bg: COLOR_BLACK_SECONDARY,
      color: COLOR_ORANGE,
      radius: "20px",
      hoverBg: COLOR_ORANGE,
      hoverColor: COLOR_BLACK,
      skype: {
        border: "1px solid #181108",
        bg: COLOR_BLACK,
      },
      social: {
        border: "1px solid #fff",
        bg: "#fff",
      },
    },
    copyright: {
      border: "1px solid #434343",
    },
  },
};
