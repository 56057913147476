import React from "react";
import { Button } from "react-bootstrap";
import { useTypewriter, Cursor } from "react-simple-typewriter";

import { Who } from "../../Assets/images";
import { Introduction } from "./WhoWeAre.Styles";

const WhoWeAre = () => {
  const [text] = useTypewriter({
    words: [
      "We have leading and qualitative solutions available for a wide range of businesses. If you are taking your baby steps with yourstartup business or adding branches to your enterprise grade venture; we’re standing strong with you.",
    ],
  });

  return (
    <>
      <Introduction>
        <div className="who-we-are-container" id="About">
          <div className="content">
            <div className="content-intro">
              <p className="content-intro-title title animation-popin">
                WHO WE ARE
              </p>
              <h1 className="content-intro-text bcolor animation-popin">
                We are here to transform your ideas into <span>reality</span>
              </h1>
              <p className="content-intro-description bcolor  animation-popin">
                {text}
                <span>
                  <Cursor />
                </span>
              </p>
              <p className="content-intro-description bcolor p20  animation-popin ">
                Our multi-talented workforce can give an amazing outlook to your
                raw ideas that can dazzle your customers. It’s time to connect
                and discuss your mind-wobbling objectives.
              </p>
              <div className="button-container">
                <Button className="btn-main animation-shake">Let’s Talk</Button>
              </div>
            </div>
          </div>
          <div className="content-images">
            <img src={Who} alt="Jesstech images" className="jesstech-image" />
          </div>
        </div>
      </Introduction>
    </>
  );
};

export default WhoWeAre;
