import styled from "styled-components";

export const FooterStyle = styled.div`
  a {
    color: ${({ theme }) => theme.global.color.septenary};
    text-decoration: none;
    font-family: ${({ theme }) => theme.global.family.raleway};
  }

  .footer {
    background-color: ${({ theme }) => theme.global.bg.primary};
    width: 100%;
    font-weight: 400;
    color: ${({ theme }) => theme.global.color.septenary};
    font-family: ${({ theme }) => theme.global.family.raleway};
    font-size: ${({ theme }) => theme.global.fonts.size};

    &-container {
      &-content {
        padding: 2rem 0;
        .address {
          letter-spacing: 1px;
          &-title {
            font-size: ${({ theme }) => theme.footer.font.size.primary};
            padding: 0px 0px 20px 0px;
            font-family: ${({ theme }) => theme.footer.font.family.poppins};
            margin: 0px;
          }
          &-text {
            padding: 0px 45px 0px 0px;
            font-family: ${({ theme }) => theme.footer.font.family.raleway};
          }
        }
        .email {
          padding-bottom: 20px;
          &-title {
            font-size: ${({ theme }) => theme.footer.font.size.primary};
            padding: 0px 0px 20px 0px;
            font-family: ${({ theme }) => theme.footer.font.family.poppins};
            margin: 0px;
          }
          &-text {
            &-body {
              white-space: nowrap;
              overflow: hidden;
              .email-icon {
                .circle-border {
                  padding: 8px;
                  background-color: ${({ theme }) => theme.footer.circle.bg};
                  border-radius: ${({ theme }) => theme.footer.circle.radius};
                  color: ${({ theme }) => theme.footer.circle.color};
                  cursor: pointer;
                }
                .circle-border:hover {
                  background-color: ${({ theme }) =>
                    theme.footer.circle.hoverBg};
                  transition: all 0.2s ease-in-out;
                  color: ${({ theme }) => theme.footer.circle.hoverColor};
                  padding: 0.5rem 0.5;
                }
              }

              .email-id {
                color: ${({ theme }) => theme.footer.font.color.primary};
                font-family: ${({ theme }) => theme.footer.font.family.raleway};
                text-decoration: none;
                overflow: hidden;
              }
              .email-id:hover {
                color: ${({ theme }) => theme.footer.font.color.secondary};
              }
            }
            .phone {
              padding-top: 10px;
              &-icon {
                .circle-border {
                  padding: 7px;
                  background-color: ${({ theme }) => theme.footer.circle.bg};
                  border-radius: ${({ theme }) => theme.footer.circle.radius};
                  color: ${({ theme }) => theme.footer.circle.color};
                  cursor: pointer;
                }
                .circle-border:hover {
                  background-color: ${({ theme }) =>
                    theme.footer.circle.hoverBg};
                  border-radius: 30px;
                  transition: all 0.2s ease-in-out;
                  color: ${({ theme }) => theme.footer.circle.hoverColor};
                  padding: 0.5rem 0.5;
                }
              }

              &-id {
                color: ${({ theme }) => theme.footer.font.color.primary};
                font-family: ${({ theme }) => theme.footer.font.family.raleway};
                text-decoration: none;
              }
              &-id:hover {
                color: ${({ theme }) => theme.footer.font.color.secondary};
              }
            }
          }
        }
        .skype {
          &-title {
            font-size: ${({ theme }) => theme.footer.font.size.primary};
            padding: 0px 0px 20px 0px;
            margin: 0px;
            font-family: ${({ theme }) => theme.footer.font.family.poppins};
          }
          &-text {
            &-container {
              .skype-text-row {
                .logo-column {
                  padding: 0px;
                  white-space: nowrap;
                  display: flex;
                  align-items: center;
                  .skype-logo {
                    text-decoration: none;
                    color: ${({ theme }) => theme.footer.font.color.primary};
                    padding-top: 5px;
                    .fa-brands.fa-skype {
                      cursor: pointer;
                      display: flex;
                      height: 30px;
                      line-height: 0px;
                      flex-wrap: wrap;
                      align-content: center;
                    }
                    .circle-border {
                      border: ${({ theme }) =>
                        theme.footer.circle.skype.border};
                      background-color: ${({ theme }) =>
                        theme.footer.circle.skype.bg};
                      border-radius: 20px;
                      cursor: pointer;
                      align-items: flex-start;
                      width: 30px;
                      height: 30px;
                      padding: 6px;
                    }
                    .circle-border:hover {
                      background-color: ${({ theme }) =>
                        theme.footer.circle.hoverBg};
                      border-radius: 30px;
                      transition: all 0.2s ease-in-out;
                      color: ${({ theme }) => theme.footer.circle.hoverColor};
                      padding: 0.5rem 0.5;
                      width: 30px;
                      height: 30px;
                    }
                  }
                }
                .logo-column {
                  /* padding: 4px 0; */
                  .skype-link {
                    text-decoration: none;
                    color: ${({ theme }) => theme.footer.font.color.primary};
                    padding: 2px 0px;
                    font-family: ${({ theme }) =>
                      theme.footer.font.family.raleway};
                    display: flex;
                    align-items: center;
                  }
                  .skype-link:hover {
                    color: ${({ theme }) => theme.footer.font.color.secondary};
                  }
                }
              }
            }
          }
          .socials {
            &-intro {
              font-size: ${({ theme }) => theme.footer.font.size.primary};
              font-weight: 400;
              padding: 20px 0px 10px 0px;
              font-family: ${({ theme }) => theme.footer.font.family.poppins};
            }
            &-container {
              padding: 0px;
              .brands-color {
                color: ${({ theme }) => theme.footer.font.color.secondary};
                cursor: pointer;
              }
              .social-row {
                padding: 0px;
                a {
                  text-decoration: none;
                }
                .w10 {
                  width: 14%;
                }
                .circle-border {
                  border: ${({ theme }) => theme.footer.circle.social.border};
                  padding: 7px;
                  background-color: ${({ theme }) =>
                    theme.footer.circle.social.bg};
                  border-radius: 20px;
                }
                .circle-border:hover {
                  background-color: ${({ theme }) =>
                    theme.footer.circle.hoverBg};
                  border-radius: 30px;
                  transition: all 0.2s ease-in-out;
                  color: ${({ theme }) => theme.footer.circle.hoverColor};
                  padding: 0.5rem 0.5;
                }
              }
            }
          }
        }
      }
      &-copyright {
        padding-top: 30px;
        border-top: ${({ theme }) => theme.footer.copyright.border};
        padding-bottom: 20px;
        .copyright-column {
          display: "flex";
          align-items: "center";
          &-content {
            color: ${({ theme }) => theme.footer.font.color.primary};
            font-weight: 400;
            font-family: ${({ theme }) => theme.footer.font.family.raleway};
            font-size: ${({ theme }) => theme.footer.font.size.secondary};
            padding-top: 13px;
            align-items: "center";
          }
        }
        .nav-menu {
          margin: 0px;
          padding: 0px;
          &-wrap {
            width: 100%;
            height: auto;
            &-container {
              margin: 0px;
              width: 100%;
              display: flex;
              .nav-items {
                display: flex;
                font-size: ${({ theme }) => theme.footer.font.size.secondary};
                width: 100%;
                &-wrap {
                  ul {
                    padding: 0;
                  }
                  &-content {
                    display: grid;
                    grid-template-columns: repeat(6, auto);
                    grid-gap: 10px;
                    list-style: none;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                    .footer-nav-col {
                      display: flex;
                      width: 100%;
                      color: #fff;
                      font-size: ${({ theme }) =>
                        theme.footer.font.size.tertiary};
                    }
                    .nav-links {
                      color: ${({ theme }) => theme.footer.font.color.primary};
                      text-decoration: none;
                      padding-right: 10px;
                      font-weight: 400;
                      font-family: ${({ theme }) =>
                        theme.footer.font.family.raleway};
                      font-size: ${({ theme }) =>
                        theme.footer.font.size.secondary};
                      word-wrap: wrap;
                      white-space: nowrap;
                    }
                    .nav-links:hover {
                      border-radius: 4px;
                      text-decoration: underline;
                      transition: all 0.2s ease-in-out;
                      color: ${({ theme }) =>
                        theme.footer.font.color.secondary};
                      padding: 0.7rem 1rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 800px) {
    .footer {
      &-container {
        &-content {
          padding: 30px;
          .address {
            padding: 0px;
          }
          .email {
            padding: 0px;
          }
          .skype {
            padding: 0px;
            &-text {
              &-container {
                margin: 0px;
                flex-direction: column;
                .skype-text-row {
                  display: flex;
                  .logo-column {
                    display: flex;
                    .skype-link {
                      color: ${({ theme }) => theme.footer.font.color.primary};
                      text-decoration: none;
                      display: flex;
                      align-items: center;
                      font-family: ${({ theme }) =>
                        theme.footer.font.family.raleway};
                    }
                  }
                }
              }
            }
            .socials {
              &-container {
                margin: 0px;
                .social-row {
                  .social-column {
                    padding-right: 3px;
                  }
                  .w10 {
                    width: 10%;
                  }
                }
              }
            }
          }
        }
        &-copyright {
          padding: 0px 30px;
          .nav-menu {
            &-wrap {
              &-container {
                .nav-items {
                  &-wrap {
                    padding: 0px;
                    &-content {
                      justify-content: flex-start;
                      padding: 0px 0px 30px 0px;
                      margin: 0px;
                      overflow: scroll;
                      .nav-links {
                        padding: 0px;
                      }
                    }
                  }
                }
              }
            }
          }
          .copyright-column {
            padding: 0px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 576px) {
    .skype-text-row {
      flex-direction: row;
      align-items: center;
    }
    .skype-link {
      margin-left: 10px;
      justify-content: center;
      color: ${({ theme }) => theme.footer.font.color.primary};
      font-family: ${({ theme }) => theme.footer.font.family.raleway};
      text-decoration: none;
    }
  }
  @media screen and (min-width: 576px) {
    .footer-container {
      max-width: 100%;
      min-width: 100%;
      padding: 2rem 8rem;
    }
  }
  @media screen and (max-width: 768px) {
    .footer-container {
      max-width: 100%;
      min-width: 100%;
      padding: 0px;
      &-copyright {
        margin: 0px;
        padding-bottom: 30px;
      }
      &-content {
        margin: 0px;
      }
      .skype-container {
        margin: 0px;
      }
      .social-container {
        margin: 0px;
      }
    }
  }
  @media screen and (min-width: 767px) and (max-width: 1200px) {
    .footer-container {
      .socials-container {
        margin: 0px;
        .social-row {
          justify-content: space-between;
        }
      }
    }
  }
  @media screen and (max-width: 375px) {
    .footer {
      &-container {
        &-content {
          .socials {
            &-container {
              .social-row {
                .social-column {
                  width: 15% !important;
                }
              }
            }
          }
        }
      }
    }
  }
`;
