import styled from "styled-components";

export const HeaderBanner = styled.div`
  .banner {
    display: flex;
    padding: 5rem 4rem;
    width: 100%;
    height: 75vh;
    background-color: ${({ theme }) => theme.heading.color.secondary};
    color: ${({ theme }) => theme.heading.color.primary};
    &-intro {
      font-family: ${({ theme }) => theme.title.family.poppins};
      flex: 1;
      padding: 40px 20px 0px 62px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      .introduction {
        align-self: flex-start;
        height: auto;
        text-align: left;
        align-items: flex-start;
        .title {
          font-size: ${({ theme }) => theme.title.font.primary};
          font-weight: 100;
          letter-spacing: 5px;
        }
        .title-intro {
          font-size: ${({ theme }) => theme.heading.font.tertiary};
          font-weight: 500;
          padding: 30px 0px 30px;
          letter-spacing: 2px;
          line-height: ${({ theme }) => theme.heading.font.height};
        }
        h1.title-intro span {
          color: ${({ theme }) => theme.heading.color.tertiary};
        }
        .title-description {
          font-size: ${({ theme }) => theme.description.font.secondary};
          font-weight: 100;
          padding: 0px 65px 0px 0px;
          letter-spacing: 1px;
          line-height: 22px;
          text-align: left;
        }
        .btn-main {
          padding: 0.7rem 1rem;
          font-family: ${({ theme }) => theme.button.family.poppins};
          background-color: ${({ theme }) => theme.button.bg.primary};
          border-radius: 10px;
          text-decoration: none;
          font-size: 13px;
          letter-spacing: 1px;
          color: ${({ theme }) => theme.button.font.color.secondary};
          font-weight: 700;
          position: static;
          border: 0;
          cursor: pointer;
        }
        .btn-main:hover {
          border-radius: 10px;
          cursor: pointer;
          background-color: ${({ theme }) => theme.button.bg.secondary};
          color: ${({ theme }) => theme.button.font.color.primary};
          height: 100%;
          border: solid 1px ${({ theme }) => theme.global.border.primary};
        }

        .button-container {
          justify-content: left;
          text-align: left;
          padding-top: 20px;
        }
      }
    }
    &-images {
      flex: 1;
      padding: 0px;
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: center;
      background-color: ${({ theme }) => theme.heading.color.secondary};
      text-align: center;
      &-ai {
        width: 60%;
        height: 100%;
        align-items: center;
        text-align: center;
        justify-content: center;
        overflow: hidden;
        transition-duration: 2.5s;
        transition-property: transform;
      }
      &-ai:hover {
        transform: rotateY(360deg);
        -webkit-transform: rotateY(360deg);
      }
    }
  }

  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }

  .column:nth-child(2) {
    justify-content: flex-start;
  }
  @media (min-width: 821px) and (max-width: 1335px) {
    .banner {
      height: auto;
      .banner-images {
        height: auto;
        width: auto;
        justify-content: center;
        .banner-images-ai {
          height: 70%;
        }
      }
    }
  }

  @media (min-width: 799px) and(max-width: 1000px) {
    .banner {
      height: auto;
      &-intro {
        flex: 1;
        padding: 20px 20px 20px 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        color: ${({ theme }) => theme.heading.color.primary};
        align-items: flex-start;
      }
      &-images {
        flex: 1;
        padding: 20px;
        display: flex;
        align-items: flex-start;
        background-color: ${({ theme }) => theme.heading.color.secondary};
        &-ai {
          width: 30%;
          height: auto;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          transition-duration: 2.5s;
          transition-property: transform;
          padding: 40px 0px 0px 0px;
        }
        &-ai:hover {
          transform: rotateY(360deg);
          -webkit-transform: rotateY(360deg);
        }
      }
    }
  }

  @media screen and (max-width: 821px) {
    .banner {
      display: block;
      padding: 40px 30px;
      width: 100%;
      height: auto;
      background-color: ${({ theme }) => theme.heading.color.secondary};
      &-intro {
        display: block;
        height: auto;
        width: 100%;
        padding: 20px 0px 20px 0px;
        .introduction {
          align-self: flex-start;
          height: auto;
          text-align: left;
          .title {
            font-size: ${({ theme }) => theme.title.font.primary};
            font-weight: 100;
            letter-spacing: 4px;
            align-items: flex-start;
          }
          .title-intro {
            font-size: ${({ theme }) => theme.heading.font.secondary};
            font-weight: 500;
            padding: 20px 0px;
            letter-spacing: 2px;
            line-height: ${({ theme }) => theme.heading.font.lineHeight};
          }
          .title-description {
            padding: 0px;
          }
          div.button-container {
            text-align: center;
          }
        }
      }
      &-images {
        display: block;
        width: 100%;
        height: auto;
        padding: 30px 0px 20px 0px;
        justify-content: center;
        &-ai {
          width: 40%;
          height: auto;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          transition-duration: 2.5s;
          transition-property: transform;
        }
        &-ai:hover {
          transform: rotateY(360deg);
          -webkit-transform: rotateY(360deg);
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .banner {
      height: auto;
      &-intro {
        .introduction {
          .title-intro {
            font-size: ${({ theme }) => theme.title.font.secondary};
            font-weight: 500;
            text-align: left;
            padding: 20px 0px;
            letter-spacing: 2px;
            line-height: 24px;
          }
          .title-description {
            padding: 0px;
          }
        }
      }
    }
  }
`;
