import styled from "styled-components";

export const OurServices = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  * {
    margin: 0;
  }
  .service-container {
    display: flex;
    padding: 0px;
    width: 100%;
    height: auto;
    margin-right: 100px;
    .sub-container {
      width: 100%;
      padding: 0px;
      .service-row {
        display: flex;
        width: 100%;
        &-img-wrap {
          display: flex;
          width: 30%;
          flex-direction: column;
          padding: 0px;
          .service-image {
            height: auto;
          }
        }

        &-intro {
          display: flex;
          font-family: ${({ theme }) => theme.title.family.poppins};
          width: 50%;
          flex-direction: column;
          padding: 2rem 0 0 1rem;

          .service-content {
            align-self: flex-start;
            height: auto;
            width: 100%;
            text-align: left;
            &-title {
              color: ${({ theme }) => theme.title.color.primary};
            }
            .title {
              font-size: ${({ theme }) => theme.title.font.primary};
              font-weight: 100;
              letter-spacing: 4px;
            }
            &-intro {
              font-size: ${({ theme }) => theme.heading.font.primary};
              font-weight: 500;
              text-align: left;
              padding: 20px 0px 10px 0px;
              letter-spacing: 2px;
              line-height: ${({ theme }) => theme.heading.font.lineHeight};
            }

            &-intro span {
              color: ${({ theme }) => theme.title.color.primary};
            }
            .bcolor {
              color: ${({ theme }) => theme.title.color.quatertiary};
            }
          }
          .service-list {
            &-body {
              white-space: nowrap;
              display: flex;
              padding: 20px 0px 0px 0px;
              &-icon {
                text-decoration: none;
                margin-top: 5px;
                .fa-solid.fa-circle-plus {
                  cursor: pointer;
                  display: flex;
                  height: auto;
                  line-height: 0px;
                  flex-wrap: wrap;
                  align-content: center;
                  font-size: ${({ theme }) => theme.description.font.tertiary};
                  color: ${({ theme }) => theme.description.color.quatertiary};
                }
                .circle-border {
                  border: 1px solid
                    ${({ theme }) => theme.description.color.quinary};
                  background-color: ${({ theme }) =>
                    theme.description.color.septenary};
                  color: ${({ theme }) => theme.description.color.quatertiary};
                  border-radius: 20px;
                  cursor: pointer;
                  width: 20px;
                  height: auto;
                  padding: 6px;
                  align-items: center;
                  justify-content: center;
                }
                .circle-border:hover {
                  background-color: ${({ theme }) =>
                    theme.description.color.quatertiary};
                  border-radius: 30px;
                  transition: all 0.2s ease-in-out;
                  color: ${({ theme }) => theme.description.color.septenary};
                  padding: 0.5rem 0.5;
                  width: 20px;
                  height: auto;
                }
              }

              &-text {
                color: ${({ theme }) => theme.description.color.quatertiary};
                text-decoration: none;
                font-size: ${({ theme }) => theme.description.font.secondary};
                font-weight: 100;
              }
              &-text:hover {
                color: ${({ theme }) => theme.description.color.septenary};
              }
            }
          }
        }
      }
    }

    .dblock {
      display: block;
    }
  }
  .service-container.slide-in {
    transform: translateY(100%); /* Slide content in from the top */
  }

  @media screen and (min-width: 1200px) {
    .service-container.container {
      width: 100%;
      max-width: 100%;
      .sub-container {
        max-width: 100%;
      }
    }
  }

  @media screen and (max-width: 821px) {
    .service-container {
      width: 100%;
      margin: 0px;
      max-width: 100%;
      padding: 30px;
      .sub-container {
        width: 100%;
        max-width: 100%;

        .service-row {
          width: 100%;
          &-img-wrap {
            display: block;
            width: 100%;
            text-align: center;
            .service-image {
              width: 50%;
              text-align: center;
              padding-left: 0px;
            }
          }
          &-void {
            display: none;
          }
          &-intro {
            width: 100%;
            .service-list {
              &-body {
                white-space: normal;
              }
            }
            .service-content {
              &-intro {
                font-size: ${({ theme }) => theme.title.font.tertiary};
                line-height: 24px;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 822px) and (max-width: 992px) {
    .service-container {
      .sub-container {
        .service-row {
          &-img-wrap {
            width: 41%;
          }
        }
      }
    }
  }
`;
