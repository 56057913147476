import styled from "styled-components";

export const Work = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  .work-container {
    background-color: ${({ theme }) => theme.global.color.septenary};
    max-width: 100%;
    height: max-content;
    justify-content: center;
    align-content: center;
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 2rem 8rem 4rem 8rem;

    &-intro {
      text-align: left;
      color: ${({ theme }) => theme.global.color.primary};

      .title-intro {
        font-size: ${({ theme }) => theme.heading.font.primary};
        font-weight: 500;
        text-align: left;
        padding: 20px 12px;
        letter-spacing: 2px;
        line-height: ${({ theme }) => theme.heading.font.lineHeight};
        display: flex;

        .icon {
          cursor: pointer;
          color: ${({ theme }) => theme.button.icon.color.primary};
          color: ${({ color }) =>
            color
              ? ({ theme }) => theme.button.icon.color.primary.secondary
              : ({ theme }) => theme.button.icon.color.tertiary};
          font-size: ${({ theme }) => theme.button.icon.size};
          background-color: ${({ theme }) => theme.button.icon.bg};
          width: 24px;
          height: 24px;
          border-radius: 20px;
          text-align: right;
          border: ${({ theme }) => theme.button.icon.border};
        }
        .icon:hover {
          color: ${({ theme }) => theme.button.icon.color.secondary};
        }
        .clicked-icon {
          color: ${({ theme }) => theme.button.icon.color.secondary};
        }
        .unclicked-icon {
          color: ${({ theme }) => theme.button.icon.color.primary};
        }
      }

      .title-intro span {
        color: ${({ theme }) => theme.heading.color.tertiary};
      }
      .col-icon {
        justify-content: end;
        align-items: center;
      }
    }

    &-content {
      padding: 10px 12px 0px 12px;
      display: flex;
      height: auto;

      .work-image {
        border-radius: 16px 0px 0px 16px;
        padding: 30px 0px 30px 0px;
        background-color: ${({ theme }) => theme.global.bg.quatertiary};
        margin: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        &-container {
          display: flex;
          align-items: center;
          justify-content: center;
          .finance-image {
            width: 400px;
            height: 240px;
          }
          .laptop-image {
            width: auto;
            height: 100vh;
          }
          .UI-image {
            width: auto;
            height: 100vh;
          }
        }
      }
      .work-content {
        font-family: ${({ theme }) => theme.title.family.poppins};
        border-radius: 0px 16px 16px 0px;
        background-color: ${({ theme }) => theme.global.bg.quinary};
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 30px 60px;

        &-description {
          align-items: center;
          justify-content: center;
          width: 100%;
          margin: 0px;

          h1 {
            font-size: ${({ theme }) => theme.description.font.primary};
            color: ${({ theme }) => theme.description.color.primary};
            font-weight: 500;
            margin: 0px;
            font-family: ${({ theme }) => theme.title.family.poppins};
          }
          p {
            font-size: ${({ theme }) => theme.description.font.septenary};
            color: ${({ theme }) => theme.description.color.secondary};
            line-height: 22px;
            font-weight: 400px;
            font-family: ${({ theme }) => theme.title.family.poppins};
            margin: 0px;
          }
          p span {
            color: ${({ theme }) => theme.description.color.primary};
          }
          .button-container {
            justify-content: left;
            text-align: left;
            padding-top: 30px;
            .btn-main {
              padding: 0.7rem 1rem;
              font-family: ${({ theme }) => theme.button.family.poppins};
              background-color: ${({ theme }) => theme.button.bg.primary};
              border-radius: 10px;
              text-decoration: none;
              font-size: 13px;
              letter-spacing: 1px;
              color: ${({ theme }) => theme.button.font.color.secondary};
              font-weight: 700;
              position: static;
              border: 0;
              cursor: pointer;
            }
            .btn-main:hover {
              border-radius: 10px;
              cursor: pointer;
              background-color: ${({ theme }) => theme.button.bg.secondary};
              color: ${({ theme }) => theme.button.font.color.primary};
              height: 100%;
              border: solid 1px ${({ theme }) => theme.global.border.primary};
            }
          }
        }
      }
    }
  }
  @media screen and (min-width: 820px) and (max-width: 1100px) {
    .work-container {
      &-content {
        .work-image {
          border-radius: 0px;
          &-container {
            padding: 0px;
            .finance-image {
              width: 100%;
              height: auto;
            }
            .laptop-image {
              width: 100%;
              height: 100vh;
            }
            .UI-image {
              width: 100%;
              height: 100vh;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .work-container {
      width: 100%;
      padding: 0px;
      .title-intro {
        align-items: center;

        h1 {
          font-size: 22px;
          text-wrap: nowrap;
        }
      }
      &-intro {
        padding: 20px 30px;
        width: 100%;
        min-width: 100%;
      }
      &-content {
        width: 100%;
        min-width: 100%;
        padding-top: 0px;
        padding-right: 0px;
        .work-content {
          padding: 30px 30px;
        }
        .work-image {
          &-container {
            padding: 0px 20px;
            .finance-image {
              width: 70%;
              height: 100vh;
            }
            .laptop-image {
              width: 70%;
              height: auto;
            }
            .UI-image {
              width: 70%;
              height: auto;
            }
          }
        }
        .work-text {
          padding: 20px 30px;
        }
      }
    }
  }
  @media screen and (min-width: 767px) {
    .work-container {
      &-content {
        .work-image {
          &-container {
            .finance-image {
              width: 90%;
              height: auto;
            }
            .laptop-image {
              width: 350px;
              height: auto;
            }
            .UI-image {
              width: 350px;
              height: auto;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 821px) {
    .work-container {
      padding: 0px;
      width: 100%;
      &-intro {
        width: 100%;
        padding: 30px 30px 0px 30px;
        margin: 0px;
      }
      &-content {
        padding: 0px;
        width: 100%;
        margin: 0px;

        h1 {
          font-size: 20px !important;
        }

        .work-image {
          padding-left: 0px;
        }
      }
    }
  }
`;
