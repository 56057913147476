import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { logoData } from "../Helpers/Constants";
import { BrandStyle } from "./Brands.Styles";

const Brands = () => {
  return (
    <>
      <BrandStyle>
        <div className="brands">
          <Container fluid className="brands-container">
            <Row width="100%" className="brands-container-row">
              {logoData.map((logo, index) => (
                <Col key={index} w="100%" h="90" className="brands-column">
                  <a href={logo.link} target="_blank" rel="noopener noreferrer">
                    <img
                      src={logo.src}
                      alt={logo.alt}
                      className="button-animate animation-shake"
                    />
                  </a>
                </Col>
              ))}
            </Row>
          </Container>
        </div>
      </BrandStyle>
    </>
  );
};

export default Brands;
