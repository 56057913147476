import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Client } from "./ClientsSay.Styles";
import { ClientData } from "../Helpers/Constants";
import { DummyVideo } from "../../Assets/images";

const ClientSay = () => {
  const [scroll, setScroll] = useState(false);
  const [prevClick, setPrevClick] = useState(false);
  const [nextClick, setNextClick] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 2600);
    });
  }, []);

  const previousWork = () => {
    setPrevClick(true);
    setNextClick(false);
    const prevIndex =
      (currentIndex - 1 + ClientData.length) % ClientData.length;
    setCurrentIndex(prevIndex);
  };

  const nextWork = () => {
    setNextClick(true);
    setPrevClick(false);
    const nextIndex = (currentIndex + 1) % ClientData.length;
    setCurrentIndex(nextIndex);
  };

  const currentWork = ClientData[currentIndex];

  return (
    <>
      <Client>
        <Container className="client">
          <Row className={`client-intro ${scroll ? "hidden" : "show"}`}>
            <Col className="title-intro bcolor animation-popin">
              <h1>
                Our <span>Clients</span> Say
              </h1>
            </Col>
          </Row>
          <Row
            className={`client-work animation-popin ${
              scroll ? "hidden" : "show"
            }`}
          >
            <Col className="col-image" md={6} xl={6} sm={12}>
              <div className="image-container animation-shake">
                <img
                  src={currentWork.image}
                  alt={currentWork.alt}
                  className={`${currentWork.class} animation-popin `}
                ></img>
                <div className="video-icon-container">
                  <a
                    rel="noopener noreferrer"
                    href={DummyVideo}
                    target="_blank"
                  >
                    <i className="fa-solid fa-play video circle-border animation-shake"></i>
                  </a>
                </div>
              </div>
            </Col>
            <Col className="col-text" md={6} xl={6} sm={12}>
              <div className="col-description">
                <Row>
                  <Col className="col-heading">
                    <h1>{currentWork.name}</h1>
                    <br />
                    <p>{currentWork.h1}</p>
                  </Col>
                  <Col className="col-comma">
                    <div className="image-container">
                      <i className="fa-solid fa-quote-right comma fa-3xl"></i>
                    </div>
                  </Col>
                </Row>

                <br />
                <span>{currentWork.description}</span>
                <br />

                <div className="icon-container title-intro bcolor col-icon">
                  <i
                    className={`fa-solid fa-circle-chevron-left icon ${
                      prevClick ? "clicked-icon" : "unclicked-icon"
                    }`}
                    onClick={previousWork}
                  ></i>
                  &nbsp;
                  <i
                    className={`fa-solid fa-circle-chevron-right icon ${
                      nextClick ? "clicked-icon" : "unclicked-icon"
                    }`}
                    onClick={nextWork}
                  ></i>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Client>
    </>
  );
};

export default ClientSay;
