import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Collapse from "react-bootstrap/Collapse";

import { Blog } from "./OurBlogs.Styles";
import { BlogContent } from "../Helpers/Constants";
import { Button, Image } from "react-bootstrap";
import Card from "react-bootstrap/Card";

const OurBlogs = () => {
  const [scroll, setScroll] = useState(false);
  const [openStates, setOpenStates] = useState(BlogContent.map(() => false));
  const [height, setHeight] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 3900);
    });
  }, []);

  const handleCollapseClick = (index) => {
    const newOpenStates = [...openStates];
    newOpenStates[index] = !newOpenStates[index];
    setOpenStates(newOpenStates);
    setHeight(true);
  };

  return (
    <>
      <Blog>
        <Container
          className={`blog-container ${scroll ? "hidden" : "show"} ${
            height ? "height" : "full-height"
          }`}
          id="Blog"
        >
          <Row className="blog-container-intro">
            <Col
              xl={10}
              lg={10}
              sm={6}
              md={6}
              xs={6}
              className="title-intro bcolor animation-popin"
            >
              <h1>
                Our <span>Blogs</span>
              </h1>
            </Col>
          </Row>
          <Row className="blog-container-content">
            {BlogContent.map((data, index) => {
              return (
                <Card
                  className={`card animation-shake ${data.class} ${
                    openStates[index] ? "initial-height" : "collapse-height"
                  }`}
                  key={index}
                >
                  <Card.Img
                    variant="top"
                    src={data.header}
                    className="card-header"
                  />
                  <Card.Body className="card-body ">
                    <Row className="header">
                      <Col className="header-profile" md={2}>
                        <Image
                          variant="left"
                          src={data.profile}
                          className="header-profile-view"
                          roundedCircle
                        />
                      </Col>

                      <Col className="name" md={4}>
                        <Card.Title className="name-text">
                          {data.name}
                        </Card.Title>
                      </Col>
                      <Col className="date" md={5}>
                        <Card.Text className="date-text">{data.date}</Card.Text>
                      </Col>
                    </Row>
                    <Card.Text className="description">
                      {data.description}
                    </Card.Text>
                    <div className="button-container">
                      <Button
                        variant="link"
                        className="card-read btn-main animation-shake"
                        onClick={() => handleCollapseClick(index)}
                        aria-controls={`example-collapse-text-${index}`}
                        aria-expanded={openStates[index]}
                      >
                        {openStates[index] ? "Close" : "Read more"}
                        &nbsp;
                        <i
                          className={`${
                            openStates[index]
                              ? "fa-solid fa-xmark"
                              : "fa-solid fa-arrow-right"
                          } `}
                        ></i>
                      </Button>
                      <Collapse in={openStates[index]}>
                        <div
                          id={`example-collapse-text-${index}`}
                          className="collapse-text"
                        >
                          {data.read}
                        </div>
                      </Collapse>
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </Row>
        </Container>
      </Blog>
    </>
  );
};

export default OurBlogs;
