import React, { useEffect, useState } from "react";
import { useTypewriter, Cursor } from "react-simple-typewriter";
import { Button } from "react-bootstrap";

import { HeaderBanner } from "./Banners.Styles.js";
import { BannerService } from "../../Assets/images/index.js";
import Brands from "./Brands.jsx";

const Banners = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 400);
    });
  }, []);

  const [text] = useTypewriter({
    words: [
      " We thrive to help businesses around the globe to accelerate digital transformation by crafting technology solutions that helps them solve problems and the world run better.",
    ],
  });

  return (
    <>
      <HeaderBanner>
        <div className="banner">
          <div className={`banner-intro ${scroll ? "hidden" : "show"}`}>
            <div className="introduction ">
              <p className="title animation-popin">
                Software Development Company
              </p>
              <h1 className="title-intro animation-popin">
                Transforming Businesses Through <span>Technology</span>
              </h1>
              <p className="title-description animation-popin">
                {text}
                <span>
                  <Cursor />
                </span>
              </p>

              <div className="button-container">
                <Button className="btn-main animation-shake">
                  Get a Free Consultation
                </Button>
              </div>
            </div>
          </div>
          <div className={`banner-images ${scroll ? "hidden" : "show"}`}>
            <img
              src={BannerService}
              alt="AI"
              className="banner-images-ai animation-popin"
            />
          </div>
        </div>
      </HeaderBanner>
      <Brands />
    </>
  );
};

export default Banners;
