import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { FooterStyle } from "./Footer.Style";
import { MenuData } from "../Helpers/Constants";

const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <FooterStyle>
        <div className="footer">
          <Container className="footer-container">
            <Row className="footer-container-content animation-popin">
              <Col className="address" md={4}>
                <h3 className="address-title">Address</h3>
                <p className="address-text">
                  601, Hamd Miracle, Near Sawan Party Plot, Sarkhej Road,
                  Ahmedabad, Gujarat.
                </p>
              </Col>
              <Col className="email" md={5}>
                <h3 className="email-title">Email</h3>
                <p className="email-text">
                  <div className="email-text-body">
                    <a
                      href="mailto:jesstechsolution@gmail.com"
                      className="email-icon"
                    >
                      <i className="fa-solid fa-envelope circle-border animation-shake1"></i>
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <a
                      href="mailto:jesstechsolution@gmail.com"
                      className="email-id"
                    >
                      jesstechsolution@gmail.com
                    </a>
                  </div>
                  <div className="phone">
                    <a href="tel:+91 7990989528" className="phone-icon">
                      <i className="fa-solid fa-phone circle-border animation-shake1"></i>
                    </a>
                    <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    <a href="tel:+91 7990989528" className="phone-id">
                      +91&nbsp;7990989528
                    </a>
                  </div>
                </p>
              </Col>

              <Col className="skype" md={3}>
                <h3 className="skype-title">Skype</h3>
                <div className="skype-text">
                  <Container className="skype-text-container">
                    <Row className="skype-text-row">
                      <div className="logo-column">
                        <a
                          href="https://www.google.com/"
                          className="skype-logo"
                        >
                          <i className="fa-brands fa-skype fa-xl circle-border animation-shake1"></i>
                        </a>
                        <span>&nbsp;&nbsp;&nbsp;</span>
                        <a
                          href="https://www.google.com/"
                          className="skype-link"
                        >
                          Jesstech.solution
                        </a>
                      </div>
                    </Row>
                  </Container>
                </div>
                <div className="socials">
                  <h3 className="socials-intro">Follow Us</h3>
                  <Container className="socials-container">
                    <Row className="social-row">
                      <Col
                        md={1}
                        className="mb-2 mb-md-0 w10 mr-1 social-column"
                        sm={2}
                      >
                        <a
                          href="https://www.facebook.com/jesstechsolution"
                          target="blank"
                        >
                          <i className="fa-brands fa-facebook brands-color circle-border animation-shake1"></i>
                        </a>
                      </Col>
                      <Col
                        md={1}
                        className="mb-2 mb-md-0 mr-1 w10 social-column"
                        sm={2}
                      >
                        <a href="https://www.google.com/" target="blank">
                          <i className="fa-brands fa-twitter brands-color circle-border animation-shake1"></i>
                        </a>
                      </Col>
                      <Col
                        md={1}
                        className="mb-2 mb-md-0 w10 social-column"
                        sm={2}
                      >
                        <a
                          href="https://www.linkedin.com/company/jesstech-solution"
                          target="blank"
                        >
                          <i className="fa-brands fa-linkedin-in brands-color circle-border animation-shake1"></i>
                        </a>
                      </Col>
                      <Col
                        md={9}
                        className="mb-2 mb-md-0 w10 social-column"
                        sm={6}
                      >
                        <a
                          href="https://www.instagram.com/jesstechsolution/"
                          target="blank"
                        >
                          <i className="fa-brands fa-instagram  brands-color circle-border animation-shake1"></i>
                        </a>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
            <Row className="footer-container-copyright">
              <Col md={6} className="copyright-column">
                <p className="copyright-column-content">
                  Copyright © {currentYear} Jesstech solution All Rights
                  Reserved.
                </p>
              </Col>
              <Col md={6} className="nav-menu">
                <div className="nav-menu-wrap">
                  <Container fluid className="nav-menu-wrap-container">
                    <Row fluid className="nav-items">
                      <Col md={12} className="nav-items-wrap " sm={12}>
                        <ul className="nav-items-wrap-content">
                          {MenuData.slice(0, 6).map((item, index) => (
                            <li key={index}>
                              <a href={item.url} className={item.cName}>
                                {item.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </FooterStyle>
    </>
  );
};

export default Footer;
