import styled from "styled-components";

export const Talk = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  .container {
    background-color: ${({ theme }) => theme.global.bg.primary};
    max-width: 100%;
    height: auto;
    margin: 0px;
    padding: 3rem 8rem 3rem 8rem;

    .discussion-content {
      text-align: center;
      color: ${({ theme }) => theme.global.color.septenary};
      &-intro {
        font-size: ${({ theme }) => theme.heading.font.primary};
        font-weight: 500;
        padding: 20px 0px;
        letter-spacing: 2px;
        line-height: ${({ theme }) => theme.heading.font.lineHeight};
      }

      &-intro span {
        color: ${({ theme }) => theme.title.color.primary};
      }
      &-text {
        font-size: ${({ theme }) => theme.description.font.quatertiary};
        color: ${({ theme }) => theme.description.color.senary};
        line-height: 22px;
        font-family: ${({ theme }) => theme.description.family.raleway};
        margin: 0px;
      }
      &-btn {
        text-align: center;
        padding-top: 30px;
        .btn-main {
          padding: 0.7rem 1rem;
          font-family: ${({ theme }) => theme.button.family.poppins};
          background-color: ${({ theme }) => theme.button.bg.primary};
          border-radius: 10px;
          text-decoration: none;
          font-size: ${({ theme }) => theme.button.font.size.primary};
          letter-spacing: 1px;
          color: ${({ theme }) => theme.button.font.color.secondary};
          font-weight: 700;
          position: static;
          border: 0;
          cursor: pointer;
        }
        .btn-main:hover {
          border-radius: 10px;
          cursor: pointer;
          background-color: ${({ theme }) => theme.button.bg.secondary};
          color: ${({ theme }) => theme.button.font.color.primary};
          height: 100%;
          border: solid 1px
            ${({ theme }) => theme.button.font.color.quatertiary};
        }
      }
    }
  }
  @media screen and (max-width: 821px) {
    .container {
      padding: 30px;
    }
  }
`;
