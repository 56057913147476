import styled from "styled-components";

export const BrandStyle = styled.div`
  .brands {
    width: 100%;
    height: auto;
    background-color: ${({ theme }) => theme.button.bg.senary};
    &-container {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: ${({ theme }) => theme.button.bg.senary};
      width: 100%;
      height: 80%;
      &-row {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${({ theme }) => theme.button.bg.senary};
        width: 100%;
        height: 80%;
        margin-left: 0;
        margin-right: 0;
        a {
          text-decoration: none;

          .button-animate {
            background-color: ${({ theme }) => theme.button.bg.senary};
            color: ${({ theme }) => theme.button.font.color.primary};
            width: 100%;
            height: 80%;
            font-weight: bold;
            font-size: ${({ theme }) => theme.button.font.size.tertiary};
            padding: 10px;
            float: left;
            margin: 0px;
            transition: 0.4s;
          }
          .button-animate:hover {
            -webkit-transition: -webkit-transform 1s, background 1s, width 1s,
              height 1s, font-size 1s;
            transition: 1s 1s 1s 1s;
            -webkit-border-radius: 5px;
            -o-transition-property: width, height, -o-transform, background,
              font-size;
            -o-transition-duration: 1s, 1s, 1s, 1s, 1s, 1s;
            -moz-transition-property: width, height, -o-transform, background,
              font-size;
            -moz-transition-duration: 1s, 1s, 1s, 1s, 1s, 1s;
            transition-property: width, height, transform, background, font-size;
            transition-duration: 1s, 1s, 1s, 1s, 1s, 1s;
            border-radius: 5px;
            background-color: ${({ theme }) => theme.button.bg.primary};
            height: 100%;
            border: solid 1px ${({ theme }) => theme.global.border.primary};
          }
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    &-container {
      padding: 0px;
      &-row {
        margin: 0;
        height: 100%;
        .brands-column {
          padding: 0px;
          a {
            .button-animate {
              padding: 0px;
              margin: 0px;
              height: 100%;
            }
            .button-animate:hover {
              border-radius: 5px;
              background-color: ${({ theme }) => theme.button.bg.primary};
              height: 100%;
              border: solid 1px ${({ theme }) => theme.global.border.primary};
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 332px) {
    .brands-container-row {
      --bs-gutter-x: 0rem;
      padding: 1px;
    }
  }
  @media (min-width: 300px) and (max-width: 800px) {
    .brands-container {
      .brands-container-row {
        .brands-column {
          padding: 0px;
          --bs-gutter-x: 0rem;
          a {
            .button-animate {
              padding: 0px;
              --bs-gutter-x: 0rem;
            }
          }
        }
      }
    }
  }
`;
