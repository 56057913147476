import styled from "styled-components";

export const Introduction = styled.div`
  .who-we-are-container {
    display: flex;
    padding: 3rem 4rem 3rem 4rem;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: ${({ theme }) => theme.global.bg.secondary};
    .content {
      font-family: ${({ theme }) => theme.title.family.poppins};
      flex: 1;
      padding: 0px 100px 20px 62px;
      flex-direction: column;
      align-items: center; /* Horizontally align the content to center */
      &-intro {
        align-self: flex-start;
        text-align: left;
        height: auto;
        &-title {
          color: ${({ theme }) => theme.title.color.primary};
        }
        .title {
          font-size: ${({ theme }) => theme.title.font.primary};
          font-weight: 100;
          letter-spacing: 4px;
        }

        &-text1 {
          font-size: ${({ theme }) => theme.heading.font.primary};
          font-weight: 500;
          padding: 20px 0px;
          letter-spacing: 2px;
          line-height: ${({ theme }) => theme.heading.font.lineHeight};
          span {
            color: ${({ theme }) => theme.title.color.primary};
          }
        }
        .bcolor {
          color: ${({ theme }) => theme.title.color.quatertiary};
        }
        &-description {
          font-size: ${({ theme }) => theme.description.font.septenary};
          font-weight: 100;
          padding: 0px 0px 0px 0px;
          letter-spacing: 1px;
          line-height: 22px;
        }
        .button-container {
          padding-top: 20px;
          .btn-main {
            padding: 0.7rem 1rem;
            font-family: ${({ theme }) => theme.button.family.poppins};
            background-color: ${({ theme }) => theme.button.bg.primary};
            border-radius: 10px;
            text-decoration: none;
            font-size: 13px;
            letter-spacing: 1px;
            color: ${({ theme }) => theme.button.font.color.secondary};
            font-weight: 700;
            position: static;
            border: 0;
            cursor: pointer;
          }
          .btn-main:hover {
            border-radius: 10px;
            cursor: pointer;
            background-color: ${({ theme }) => theme.button.bg.secondary};
            color: ${({ theme }) => theme.button.font.color.primary};
            height: 100%;
            border: solid 1px ${({ theme }) => theme.global.border.primary};
          }
        }

        .p20 {
          padding-top: 20px;
        }
      }
    }
    .content-images {
      flex: 1;
      padding: 0px;
      display: flex;
      flex-direction: column;
      height: auto;
      align-items: center;
      background-color: ${({ theme }) => theme.global.bg.secondary};
      text-align: center;

      .jesstech-image {
        width: 320px;
        height: auto;
      }
    }
  }
  .hidden {
    opacity: 0;
    transition: all 6s ease-in-out;
  }
  .hide-image {
    opacity: 0;
    transition: all 0s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }

  .column:nth-child(2) {
    justify-content: flex-start;
  }

  @media screen and (max-width: 821px) {
    .who-we-are-container {
      display: block;
      padding: 30px;
      width: 100%;
      height: auto;
      background-color: ${({ theme }) => theme.global.bg.secondary};
      .content {
        display: block;
        width: 100%;
        padding: 0px 0px 20px 0px;
        &-intro {
          align-self: flex-start;
          text-align: left;
          height: auto;
          &-title {
            color: ${({ theme }) => theme.title.color.primary};
          }
          .title {
            font-size: ${({ theme }) => theme.title.font.primary};
            font-weight: 100;
            letter-spacing: 4px;
          }
          &-text1 {
            font-size: ${({ theme }) => theme.heading.font.secondary};
            font-weight: 500;
            padding: 20px 0px;
            letter-spacing: 2px;
            line-height: ${({ theme }) => theme.heading.font.lineHeight};
          }
          &-description {
            padding: 0px;
          }
          .p20 {
            padding-top: 20px;
          }
          .button-container {
            text-align: center;
          }
        }
      }

      .content-images {
        display: block;
        width: 100%;
        padding: 0px 0px 20px 0px;
        .jesstech-image {
          padding-right: 13px;
          width: 50%;
        }
      }
    }
  }
  @media screen and (max-width: 350px) {
    .who-we-are-container {
      .content {
        &-intro {
          &-text1 {
            font-size: ${({ theme }) => theme.title.font.secondary};
            font-weight: 500;
            padding: 20px 0px;
            letter-spacing: 2px;
            line-height: 24px;
          }
          &-description {
            padding: 0px;
          }
        }
      }
    }
  }
`;
