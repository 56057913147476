import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
}

/**
 * Fix fonts that render as bold in Firefox
 */

html {
  font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}



@keyframes shake {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
     transform: translateY(0px);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
   transform: translateY(0px);
  }
}
@keyframes shake1 {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-20px);
  }
  50% {
     transform: translateY(0px);
  }
  75% {
    transform: translateY(20px);
  }
  100% {
   transform: translateY(0px);
  }
}



@keyframes anim-popoutin {
    0% {
      color: ${({ theme }) => theme.global.color.primary};
      transform: scale(0);
      opacity: 0;
      text-shadow: 0 0 0 rgba(0, 0, 0, 0);
    }
    25% {
      transform: scale(1);
      opacity: 1;
      text-shadow: 3px 10px 5px rgba(0, 0, 0, 0.5);
    }
  }
  @keyframes line {
    0% {
      clip-path: polygon(0 0, 0 100%, 0% 100%, 0% 0);
    }
    50% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
    100% {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    }
  }

.animation-popin{
  animation: 4s anim-popoutin ease-in-out alternate;
}
.animation-shake{
}
.animation-shake:hover{
  animation: shake 0.4s;
}
.animation-shake1:hover{
  animation: shake 1s;
}
.line{
  animation: line 4s linear forwards infinite;

}

`;

export default GlobalStyle;
