import styled from "styled-components";

export const Approach = styled.div`
  .hidden {
    opacity: 0;
    transition: all 4s ease-in-out;
  }
  .show {
    opacity: 1;
    transition: all 3s ease-in-out;
  }
  .approach-container {
    background-color: ${({ theme }) => theme.global.bg.tertiary};
    max-width: 100%;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px;
    .approach-intro {
      text-align: center;
      color: ${({ theme }) => theme.global.color.primary};

      &-title {
        justify-content: center;
        font-size: ${({ theme }) => theme.heading.font.primary};
        font-weight: 500;
        padding: 20px 12px;
        letter-spacing: 2px;
        line-height: ${({ theme }) => theme.heading.font.lineHeight};
        display: flex;
      }
      &-title span {
        color: ${({ theme }) => theme.heading.color.tertiary};
      }
    }
    .mobile-view {
      display: none;
    }

    .approach-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      &-wrap {
        width: 100%;
        display: flex;
        justify-content: center;
        margin: auto;
        padding: 4rem 0rem 1rem 0rem;

        .col-icons {
          display: inline-block;
          position: relative;
          width: 100px;

          .circle {
            width: 90px;
            height: 90px;
            border-radius: ${({ theme }) => theme.approach.radius};
            background-color: ${({ theme }) => theme.approach.bgcolor};
            border: ${({ theme }) => theme.approach.border};
            justify-content: center;
            align-items: center;
            color: ${({ theme }) => theme.approach.color};
            .icon {
              width: 80%;
              justify-content: center;
              display: flex;
            }
          }
          .row-text {
            width: 100%;
            display: flex;
            padding-top: 10px;
            .text {
              padding: 0rem 0rem 0rem 0rem;
              font-size: ${({ theme }) => theme.description.font.septenary};
              background-color: ${({ theme }) => theme.global.bg.tertiary};

              .header {
                padding: 5px 0px 0px 8px;
                font-weight: bold;
                color: ${({ theme }) => theme.description.color.primary};
                margin: 0px;
              }
              .description {
                color: ${({ theme }) => theme.description.color.primary};
                font-family: ${({ theme }) => theme.description.family.raleway};
                padding: 2px 0px 0px 8px;
                text-align: left;
              }
              .description-content {
                color: ${({ theme }) => theme.description.color.primary};
                font-family: ${({ theme }) => theme.description.family.raleway};
                padding: 2px 0px 0px 15px;
              }
              .text-content {
                padding: 5px 0px 0px 15px;
                font-weight: bold;
                color: ${({ theme }) => theme.description.color.primary};
                text-align: left;
                margin: 0px;
              }
            }
          }
        }

        .col-line-bottom {
          display: inline-block;
          width: 200px;
          height: 60px;
          border: none;
          border-bottom: dashed 2px orange;
          padding: 40px 40px;
          margin: 0px 5px;
          border-radius: 0 0 50% 50%;
          clip-path: polygon(0 0, 100% 50%, 100% 100%, 100% 100%);
        }

        .col-design {
          justify-content: center;
          display: inline-block;
          position: relative;
          padding: 20px;
          width: 120px;
          height: 120px;
          z-index: 999;
          border-radius: ${({ theme }) => theme.approach.radius};
          background-color: ${({ theme }) => theme.approach.bgcolor};
          border: ${({ theme }) => theme.approach.border};
        }
        .col-line-top {
          display: inline-block;
          width: 200px;
          height: 60px;
          border: none;
          border-top: dashed 2px orange;
          padding: 40px 40px;
          margin: 0px 5px;
          border-radius: 70%;
          clip-path: polygon(0 0, 100% 50%, 100% 100%, 100% 100%);
          display: inline-block;
        }
        .col-develop {
          justify-content: center;
          display: inline-block;
          position: relative;
          padding: 20px;
          width: 120px;
          height: 120px;
          z-index: 999;
          border-radius: ${({ theme }) => theme.approach.radius};
          background-color: ${({ theme }) => theme.approach.bgcolor};
          border: ${({ theme }) => theme.approach.border};
        }
        .col-deliver {
          justify-content: center;
          display: inline-block;
          position: relative;
          padding: 20px;
          width: 120px;
          height: 120px;
          z-index: 999;
          border-radius: ${({ theme }) => theme.approach.radius};
          background-color: ${({ theme }) => theme.approach.bgcolor};
          border: ${({ theme }) => theme.approach.border};
        }
      }
    }
  }

  @media screen and (max-width: 1054px) {
    .desktop-view {
      display: none !important;
    }

    .mobile-view {
      display: flex !important;
      padding: 0px 30px 30px;

      .mobile-content {
        &-wrap {
          width: 100%;
          justify-content: center;
          margin: auto;
          padding: 0rem 0rem 1rem 0rem;

          .h-20 {
            height: 20px;
          }

          .icon {
            width: 70px;
            justify-content: center;
            display: flex;
          }

          .col-icons {
            position: relative;
            width: 70px;

            .circle {
              width: 70px;
              height: 70px;
              border-radius: ${({ theme }) => theme.approach.radius};
              background-color: ${({ theme }) => theme.approach.bgcolor};
              border: ${({ theme }) => theme.approach.border};
              justify-content: center;
              align-items: center;
              color: ${({ theme }) => theme.approach.color};
            }
          }

          .col-content {
            width: auto;
            padding: 0px 0px 0px 25px;
            margin: 0px;
            align-items: center;
            display: flex;
          }

          .row-text {
            width: 100%;
            display: flex;
            padding-top: 10px;

            .text {
              padding: 0;
              font-size: ${({ theme }) => theme.description.font.septenary};
              background-color: ${({ theme }) => theme.global.bg.tertiary};

              .header {
                font-size: 14px;
                padding: 5px 0px 0px 8px;
                font-weight: bold;
                color: ${({ theme }) => theme.description.color.primary};
                margin: 0;
              }

              .description {
                font-size: 12px;
                color: ${({ theme }) => theme.description.color.primary};
                font-family: ${({ theme }) => theme.description.family.raleway};
                padding: 2px 0px 0px 8px;
                text-align: left;
              }
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 700px) {
    .approach-container {
      padding: 0px 20px;

      .approach-intro {
        padding: 20px 10px;

        &-title {
          font-size: ${({ theme }) => theme.heading.font.secondary};
        }
      }

      .mobile-view {
        display: block;

        .mobile-content {
          &-wrap {
            .col-icons {
              width: 60px;

              img {
                width: 60px;
                height: 60px;
              }

              .circle {
                width: 60px;
                height: 60px;
              }
            }

            .row-text {
              .text {
                .header {
                  font-size: 15px;
                }

                .description {
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
  }

  @media screen and (min-width: 820px) and (max-width: 1100px) {
    .approach-container {
      .row-work {
        .col-image {
          border-radius: 0px;
          .image-container {
            padding: 0px;
            .finance-image {
              width: 100%;
              height: auto;
              z-index: 1;
            }
            .laptop-image {
              width: 100%;
              height: auto;
              z-index: 1;
            }
            .UI-image {
              width: 100%;
              height: auto;
              z-index: 1;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    .approach-container {
      width: 100%;
      padding: 0px;
      height: 100%;
      .approach-intro {
        width: 100%;
        padding: 20px 30px;
      }

      .row-work {
        padding-top: 0px;
        padding-right: 0px;
        .col-image {
          .image-container {
            padding: 0px 20px;
            .finance-image {
              width: 100%;
              height: auto;
              z-index: 1;
            }
            .laptop-image {
              width: 100%;
              height: auto;
              z-index: 1;
            }
            .UI-image {
              width: 100%;
              height: auto;
              z-index: 1;
            }
          }
        }

        .col-text {
          padding: 20px 30px;
        }
      }
    }
  }
`;
