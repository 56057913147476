import React, { useState } from "react";

import { NavbarStyles } from "./Navbar.Styles.js";
import { NavMobile } from "./NavMobile.Styles.js";
import { MenuData } from "../Helpers/Constants.jsx";
import { JesstechLogo } from "../../Assets/images/index.js";

export const Navbar = () => {
  const [state, setState] = useState(false);

  return (
    <>
      <NavbarStyles>
        <div className="navbar">
          <nav className="navbar-items">
            <h1 className="logo">
              <img
                src={JesstechLogo}
                alt="JessTech logo"
                className="jesstech-logo"
              ></img>
            </h1>
            <div className="menu-icons" onClick={() => setState(!state)}>
              <i
                className={
                  state
                    ? "fas fa-times animation-shake"
                    : "fas fa-bars animation-shake"
                }
              ></i>
            </div>
            <ul className={state ? "nav-menu active " : "nav-menu "}>
              {MenuData.map((item, index) => {
                return (
                  <li key={index} className="animation-shake">
                    <a
                      href={item.url}
                      className={`animation-shake ${item.cName}`}
                    >
                      {item.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </nav>
        </div>
      </NavbarStyles>
    </>
  );
};

export const NavbarMobile = () => {
  return (
    <>
      <NavMobile>
        <div className="home"></div>
      </NavMobile>
    </>
  );
};
