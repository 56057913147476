import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { Approach } from "./OurApproach.Styles";
import { Discover, Design, Develop, Deliver } from "../../Assets/images";

const OurApproach = () => {
  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY < 3000);
    });
  }, []);

  const approaches = [
    {
      icon: Discover,
      title: "Discover",
      description: "Turn your idea from concept to MVP",
    },
    {
      icon: Design,
      title: "Design",
      description: "Sketch out the product to align the user needs",
    },
    {
      icon: Develop,
      title: "Develop",
      description: "Convert the designs into a live application",
    },
    {
      icon: Deliver,
      title: "Deliver",
      description: "Launching the application to the market",
    },
  ];

  return (
    <>
      <Approach>
        <Container className="approach-container">
          <Row className={`approach-intro ${scroll ? "hidden" : "show"}`}>
            <Col className="approach-intro-title bcolor animation-popin">
              <h1>
                Our Approach To Reach Your <span>Business</span> Goals
              </h1>
            </Col>
          </Row>

          <div className="approach-content desktop-view">
            <Row className="approach-content-wrap animation-popin">
              {approaches.map((step, index) => (
                <React.Fragment key={index}>
                  <Col xl={2} sm={2} className="col-icons">
                    <Row className="circle">
                      <img
                        src={step.icon}
                        alt={step.title.toLowerCase()}
                        className="icon"
                      />
                    </Row>
                    <div className="row-text">
                      <Row className="text animation-popin">
                        <p className="header animation-popin">{step.title}</p>
                        <p className="description animation-popin">
                          {step.description}
                        </p>
                      </Row>
                    </div>
                  </Col>
                  {index < 3 && (
                    <Col
                      xl={1}
                      sm={1}
                      className={`col-line-${
                        index % 2 === 0 ? "bottom" : "top"
                      } line`}
                    ></Col>
                  )}
                </React.Fragment>
              ))}
            </Row>
          </div>

          <div className="mobile-content mobile-view">
            <div className="mobile-content-wrap animation-popin">
              {approaches.map((step, index) => (
                <React.Fragment key={index}>
                  <Row className="circle">
                    <Col xs={2} sm={2} className="col-icons">
                      <img
                        src={step.icon}
                        alt={step.title.toLowerCase()}
                        className="icon"
                      />
                    </Col>
                    <Col xs={10} sm={10} className="col-content">
                      <div className="row-text">
                        <div className="text">
                          <p className="header">{step.title}</p>
                          <p className="description">{step.description}</p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {index < 3 && <div className="h-20"></div>}
                </React.Fragment>
              ))}
            </div>
          </div>
        </Container>
      </Approach>
    </>
  );
};

export default OurApproach;
